import React, {useState } from 'react'
import {
    BrowserRouter as Router,
    Route,
    Switch,
  } from 'react-router-dom';
import Nav from './components/Nav'
import { useAuthentication } from './hooks/useAuthentication'
import { useGlobalState } from './hooks/useGlobalState'

import ChangeLog from './pages/ChangeLog'

import Unauthorized from './components/Unauthorized'
// import Login from './pages/Login'
import Installers from './pages/Installers'
import LiveInstallers from './pages/Installers/LiveInstallers'
import LiveInstaller from './pages/Installer/LiveInstaller'
import Installer from './pages/Installer'
import Survey from './pages/Survey'
import ActionPlan from './pages/ActionPlan'
import LiveActionPlan from './pages/ActionPlan/LiveActionPlan'
import Bds from './pages/Bds'
import logo_en from './assets/logo_en.png'

import OAUTH from './pages/OAUTH'

import Refresh from './components/Refresh'

import OfflineSync from './components/OfflineSync'
import WaitingToSync from './components/WaitingToSync'
import Breadcrumbs from './components/Breadcrumbs'

import { useTranslation } from 'react-i18next'

import ScrollRestoration from './ScrollRestoration'

export default function Routes() {

    const { isAuthenticated, user } = useAuthentication()
    const { bds } = useGlobalState()
    const { i18n } = useTranslation()

    const RootComponentByUser = {
        'DIRECTOR': !bds ? Bds : LiveInstallers,
        'HEAD_OFFICE': !bds ? Bds : LiveInstallers,
        'BUSINESS_DEVELOPMENT_SPECIALIST': Installers
    }

    const RootComponent = RootComponentByUser[user.userType]

    if (!isAuthenticated) return (
        <UnauthorizedLayout>
            <Router>
                <Switch>
                    <Route path='/' exact render={() => window.location.replace(`${ process.env.NODE_ENV === 'development' ? 'http://localhost:3100' : 'https://mynapaprograms.com' }/l/${ i18n.language }/on_track`) } />
                    <Route path='/oauth/:language/:token' component={ OAUTH } />
                </Switch>
            </Router>
            {/* <Login /> */}
        </UnauthorizedLayout>
    )

    // test push

    // If BDS user, run the offlineSync
    const SyncContainer = user.userType === 'BUSINESS_DEVELOPMENT_SPECIALIST' ? OfflineSync : React.Fragment

    return (
        <SyncContainer>
            <Router>
                <ScrollRestoration />
                <AuthorizedLayout>

                    {
                        user.userType !== 'BUSINESS_DEVELOPMENT_SPECIALIST' && <Breadcrumbs/>
                    }

                    <Switch>
                        <Route path='/' exact component={ RootComponent } />
                        <Route exact path='/installer/:installerNavisionId' component={ user.userType === 'BUSINESS_DEVELOPMENT_SPECIALIST' ? Installer : LiveInstaller } />
                        <Route path='/installer/:installerNavisionId/survey' render={ (props) => <Survey { ...props } step='SURVEY'/> } />
                        <Route  exact path='/installer/:installerNavisionId/action-plan' render={ (props) => <Survey { ...props } step='ACTION_PLAN'/> } />
                        <Route path='/installer/:installerNavisionId/action-plan/:uuid' component={ user.userType === 'BUSINESS_DEVELOPMENT_SPECIALIST' ? ActionPlan : LiveActionPlan } />
                        <Route path='/release-notes' component={ ChangeLog } />
                        <Route path='/oauth/:language/:token' component={ OAUTH } />
                    </Switch>
                </AuthorizedLayout>
            </Router>
        </SyncContainer>
    )
}

function UnauthorizedLayout({ children }) {

    return (
        <Unauthorized>
            <Refresh />
            { children }
        </Unauthorized>
    )

}

function AuthorizedLayout({ children }) {

    const setStatsOpen = useState()[1]

    return (
        <>
            <Refresh />
            <header className='hideOnPrint'>
                <div style={{ maxWidth: '1024px', margin: '0 auto', position: 'relative' }}>
                    <img className='logo' style={{ maxHeight: '50px' }} src={logo_en} alt='NAPA - OnTrack' />
                    <WaitingToSync />
                    <Nav onStatsChange={(state) => setStatsOpen(state)} />
                </div>
            </header>
            <div className='main'>
                { children }
            </div>
        </>
    )

}