import Dexie from 'dexie'

const db = new Dexie('OnTrack')

// db.version(1).stores({
//     surveys: 'id',
//     installers: 'id, navisionId',
//     responses: 'uuid, installerNavisionId, surveyStatus, syncStatus, createdAt',
// });

db.version(1).stores({
    surveys: 'id',
    installers: 'id, navisionId',
    responses: 'uuid, installerNavisionId, surveyStatus, syncStatus, createdAt',
});

//  (async function() {
//     await db.answers.put({ installerNavisionId: 'NT0004711', bdsId: 1, value: 'didnt use a pos system', tags: ['ONE','TWO'], questions: {one:{one:1,two:{two:2}},two:{two:2}} })
//  })()

export default db