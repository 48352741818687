import React, { useState } from "react";
import InputWrapper from '../InputWrapper'
import { useSurvey } from '../useSurvey'
// import accents from 'remove-accents'

const DATA_TYPE_FUNCTIONS = {
    STRING: (e) => e,
    TEXT: (e) => e,
    BOOLEAN: (e) => e,
    INTEGER: (e) => e.replace(/[^0-9]/g, ""),
    FLOAT: (e) => e.replace(/[^0-9.]/g, ""),
    JSON: (e) => e,
    FILE: (e) => e,
}

// NOTE: The type = "text" declaration below serves as a default value for that component property
export default function Input({ label, name, value = '', type = "text", inputMode = 'text', helper = '', required = false, onChange, placeholder, autoComplete = 'on' }) {
    // const [stateValue, setValue] = useState(value);
    const [isFocused, setFocus] = useState(false)

    // Use global form state
    const { state: { inputs }, setAnswer } = useSurvey()

    // On init, set object name
    // useEffect(() => {
    //     if (!inputs[name]) {
    //         setSurveyValue({
    //             [name]: {
    //                 value,
    //                 actionPriority: 0,
    //                 notes: null,
    //                 points: 0
    //             }
    //         })
    //     }
    // }, [])

    const inputId = `f_${name}`

    if (autoComplete === 'on') {
        if (name === 'username') {
            autoComplete = 'username'
        }
        if (type === 'password') {
            autoComplete = 'current-password'
        }
    }

    return (
        <InputWrapper label={label} inputId={inputId} isFocused={ isFocused } isFilled={ inputs[name] && inputs[name].value ? true : false } helper={ helper } >
            <input 
                id={inputId}
                type={type}
                inputMode={ inputMode }
                name={name}
                // required={required}
                value={ inputs[name] && inputs[name].value ? inputs[name].value : value }
                onChange={e => {

                    const { qualifier } = inputs[name]
                    const value = DATA_TYPE_FUNCTIONS[inputs[name].dataType](e.target.value)
                    setAnswer({
                        [name]: { ...inputs[name], value, pointsEarned: qualifier ? 0 : value.length > 0 ? inputs[name].pointValue : 0 }
                    });

                    // Allow listening to onChange for this specific input
                    if (onChange) onChange({ target: { name: e.target.name, value: e.target.value } })
                }}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                autoComplete={ autoComplete }
                placeholder={ placeholder }
            />
            {
                required ? 
                <span className="required-hint"></span> 
                :
                null
            }
        </InputWrapper>
    );
}
