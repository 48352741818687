import React from "react";
import './InputWrapper.css'

export default function InputWrapper({ children, isFocused, isFilled, inputId, label }) {

    return (
        <>
            <label htmlFor={inputId}>{ label }</label>
            <div className={ `input-container ${isFilled ? 'input-filled' : ''} ${isFocused ? 'input-focus' : ''}` } > 
                { children }            
            </div>
        </>
    )
}