import React from 'react'
import './card.css'
import GoldStandard from '../GoldStandard'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function Card({ onClick, goldStandard, children, style, to, className, dark }) {

    const El = to ? Link : 'div'

    return (
        <El className={`card ${ goldStandard ? 'card--goldStandard' : '' } ${ className } ${ dark ? 'card--dark' : '' } ${ onClick ? 'card--button' : '' }`} 
            onClick={ (e) => onClick && onClick(e) }
            to={ to }
            style={ style }
        >
            { goldStandard && <div className='goldStandardIcon'><GoldStandard /></div> }
            { children }
        </El>
    )
}

export function CardPointTitle({ children, title }) {
    return (
        <div className='cardPointTitleContainer'>
            <div className='cardPointTitle'>{ title }</div>
            { children }
        </div>
    )
}

export function CardPriorityTitle({ children, priority }) {
    const { t } = useTranslation()

    const priorities = {
        high: t('cardPriorityTitleHigh', 'high priority'),
        medium: t('cardPriorityTitleMedium', 'medium priority'),
        low: t('cardPriorityTitleLow', 'low priority')
    }

    return (
        <div className={`cardPriorityTitleContainer cardPriorityTitleContainer--${ priority }`}>
            <div className='cardPriorityTitle'>{ priorities[priority] }</div>
            <div className='cardPriorityItemCount'>{ children }</div>
            { t('actionPlanItemsLabel', 'Items') }
        </div>
    )
}

export function CardDivider({ horizontal = true, title }) {

    return (
        <div className='cardDivider'>
            {
                title && (
                    <div className='cardDividerTitle'>{ title }</div>
                )
            }
        </div>
    )

}

export function CardStatPoints({ goldStandard, children, sm, className }) {
    return (
        <div className={`cardStatPoints ${ goldStandard ? 'cardStatPoints--goldStandard' : 'cardStatPoints--overall' } ${ sm ? 'cardStatPoints--small' : '' } ${ className ? className : '' }`}>
            { children }
        </div>
    )
}

export function CardPriorityHighlight({ priority }) {
    return <div className={`cardPriorityHighlight cardPriorityHighlight--${ priority }`} />
}