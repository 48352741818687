import React from 'react'
import Card from '../../components/Card'

import InstallerCard from '../../components/InstallerCard'
import { Graph } from '../../components/DualPointGraph'

import './installer.css'
import Formatting from '../../components/Formatting'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-apollo'
import { GET_RESPONSES } from '../../operations/Response'
import { GET_INSTALLER } from 'operations/Installer'

import Loader from '../../components/Loader'

export default function Installer({ match }) {

    const { installerNavisionId } = match.params

    const { error, loading, data } = useQuery(GET_INSTALLER, { variables: { filter: { navisionId: { eq: installerNavisionId } } } })

    if (error) return null

    if (loading) return <Loader />

    const { installer } = data

    return <HistoricalData installer={ installer } />
}

function InstallerActionPlans({ installerNavisionId, surveyData: { overallPoints, goldStandardPoints, answers, createdAt, surveyStatus, uuid } }) {
    const { t } = useTranslation()
    
    const highPriorityItems = Object.keys(answers).filter(key => answers[key].actionPriority / 400 === 3 || answers[key].actionPriority === 3)
    const mediumPriorityItems = Object.keys(answers).filter(key => answers[key].actionPriority / 400 === 2 || answers[key].actionPriority === 2)
    const lowPriorityItems = Object.keys(answers).filter(key => answers[key].actionPriority / 400 === 1 || answers[key].actionPriority === 1)

    return (
        <>
            <Card className='cardHistory' // TODO: enable opening past action plan (need to open separate from the existing action plan. too much going on with state) to={`/installer/${ installerNavisionId }/action-plan/${ uuid }`}
                to={`/installer/${ installerNavisionId }/action-plan/${ uuid }`}
            >
                <div className='history'>
                    <Graph overallPoints={ overallPoints } goldStandardPoints={ goldStandardPoints } hidePoints />

                    <div className='cardHistoryDetail cardHistoryDetail--yellow'>
                        <h2>Gold Standard</h2>
                        { goldStandardPoints }/150
                    </div>

                    <div className='cardHistoryDetail cardHistoryDetail--blue'>
                        <h2>OnTrack</h2>
                        { overallPoints }/300
                    </div>

                    <div className='cardHistoryDivider'/>

                    <div className='cardHistoryDetail cardHistoryDetail--red'>
                        <h2>{ t('highPriority', 'High Priority') }</h2>
                        { highPriorityItems.length }
                    </div>

                    <div className='cardHistoryDetail cardHistoryDetail--orange'>
                        <h2>{ t('mediumPriority', 'Medium Priority') }</h2>
                        { mediumPriorityItems.length }
                    </div>

                    <div className='cardHistoryDetail cardHistoryDetail--yellow'>
                        <h2>{ t('lowPriority', 'Low Priority') }</h2>
                        { lowPriorityItems.length }
                    </div>

                    <div className='cardHistoryDetail cardHistoryDetail--right'>
                        <h2>{ surveyStatus === 'INCOMPLETE' ? t('actionPlanStartedOnLabel', 'Started On') : t('actionPlanFinalizedOnLabel', 'Finalized On') }</h2>
                        <Formatting format='DATE_SHORT'>{ Number(createdAt) }</Formatting>
                    </div>

                </div>
            </Card>
        </>
    )

}

function HistoricalData({ installer }) {

    const { t } = useTranslation()

    const { loading, error, data } = useQuery(GET_RESPONSES, {
        variables: {
            filter: {
                installerNavisionId: {
                    eq: installer.navisionId
                }
            }
        }
    })

    if (loading) return 'Loading...'
    if (error) return 'Error'

    const { responses } = data.onTrack

    return (
        <>
            <InstallerCard installer={ installer } surveyData={ responses[0] || [] } />

            <h2>{ t('actionPlanHistoryTitle', 'History') }</h2>
            {
                responses.map(response => {
                    return <InstallerActionPlans key={ response.uuid } surveyData={ response } installerNavisionId={ installer.navisionId } />
                })
            }
        </>
    )
    
}