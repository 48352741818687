import React, { createContext, useContext, useState, useEffect } from 'react'
import calculatePointValue from "./Calculate";
import db from '../../offlineDb'
import { useSyncronization } from '../../hooks/useSyncronization'

const SurveyContext = createContext()

export default function Survey({ children, onChange, onSubmit, init = {}, responseKey, createdAt, lastSync, installerNavisionId, survey = {} }) {

    const [ overallPoints, setOverallPoints ] = useState(0)
    const [ goldStandardPoints, setGoldStandardPoints ] = useState(0)

    const { setSyncronized } = useSyncronization()

    const [state, setState] = useState({
        submitted: false,
        signature: '',
        inputs: init
    })

    useEffect(() => {
        if (state.inputs) {

            // move qualifiers to here...need to update points when dependency question changes as well

            let newOverallPoints = 0
            let newGoldStandardPoints = 0
            Object.keys(state.inputs).map(input => {
                newOverallPoints = newOverallPoints + state.inputs[input].pointsEarned
                if (state.inputs[input].goldStandard) newGoldStandardPoints = newGoldStandardPoints + state.inputs[input].pointsEarned
                return null
            })
            setOverallPoints(newOverallPoints)
            setGoldStandardPoints(newGoldStandardPoints)
        }
    }, [state])

    // Autosave
    useEffect(() => {

        const debounce = setTimeout(async () => {
            const exists = await db.responses.where({ uuid: responseKey }).toArray()
            if (exists.length) await db.responses.update(responseKey, { uuid: responseKey, surveyStatus: state.submitted ? 'COMPLETE' : 'INCOMPLETE', syncStatus: 'DESYNCRONIZED', answers: state.inputs, signature: state.signature, overallPoints, goldStandardPoints })
            if (!exists.length) await db.responses.put({ uuid: responseKey, createdAt, installerNavisionId, surveyStatus: 'INITIALIZING', syncStatus: 'DESYNCRONIZED', answers: state.inputs, signature: state.signature, overallPoints, goldStandardPoints })
            setSyncronized(false)
        }, 1000)

        return () => {
            clearTimeout(debounce)
        }
    // eslint-disable-next-line
    }, [ state, overallPoints, goldStandardPoints ])

    useEffect(() => {
        if (onChange) {
            onChange({
                state,
                overallPoints,
                goldStandardPoints
            })
        }
    })

    function handleSetState(input) {
        setState(state => {

            const updatedInputs = { ...state.inputs, ...input }

            // console.log('INPUT:', input)
            
            updatedInputs[Object.keys(input)[0]].lastUpdated = new Date().getTime()
            updatedInputs[Object.keys(input)[0]].desyncronized = true
            if (state.inputs[Object.keys(input)[0]].qualifier) {
                updatedInputs[Object.keys(input)[0]].pointsEarned = calculatePointValue(state.inputs[Object.keys(input)[0]].qualifier, updatedInputs)
            }

            return ({
                ...state,
                inputs: updatedInputs
            })
        })
    }

    function handleSetInitialState(input) {
        setState(state => ({
            ...state,
            inputs: { ...state.inputs, ...input }
        }))
    }

    function handleSurveyReset() {
        setState(state => ({
            ...state,
            inputs: {}
        }))
    }

    function handleEnableSubmit() {
        setState(state => ({
            ...state,
            submitted: false
        }))
    }

    function handleFinalizeSurvey(signature) {
        setState(state => ({
            ...state,
            submitted: true,
            signature
        }))
    }

    return(
        <SurveyContext.Provider
            value={{
                state,
                survey,
                overallPoints,
                goldStandardPoints,
                setInitialState: (input) => handleSetInitialState(input),
                setAnswer: (input) => handleSetState(input),
                finalizeSurvey: (signature) => handleFinalizeSurvey(signature),
            }}
        >
            <form
                onSubmit={(e) => {
                    e.preventDefault();

                    setState(state => ({
                        ...state,
                        submitted: true
                    }))

                    if (onSubmit) {
                        onSubmit({inputs: state.inputs, resetSurvey: handleSurveyReset, enableSubmit: handleEnableSubmit})
                    }
                }}
            >
                { children }
            </form>
        </SurveyContext.Provider>
    )

}

function useSurvey() {
    return useContext(SurveyContext)
}

export {
    useSurvey,
    SurveyContext
}