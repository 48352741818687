import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser'

import Routes from './Routes'
import GlobalStateProvider from './hooks/useGlobalState'
import AuthenticationProvider from './hooks/useAuthentication'
import RefreshProvider from './hooks/useRefresh'
import './styles/index.css';
import * as serviceWorker from './serviceWorker';
import { IntlProvider } from 'react-intl';
import TueriProvider from '@tueri/react';
import SyncronizationProvider from './hooks/useSyncronization'
import NetworkProvider from './hooks/network'

import { ApolloProvider } from 'react-apollo'
import client from './Apollo'
// import db from './offlineDb'

import './i18n'

Sentry.init({
    dsn: "https://736e8e38793d4ae8b9324f53edb835b4@sentry.io/1770663",
    environment: process.env.NODE_ENV
})

ReactDOM.render(
    <NetworkProvider>
        <RefreshProvider>
            <IntlProvider defaultLocale='en' locale='en'>
                <TueriProvider>
                    <ApolloProvider client={ client }>
                        <GlobalStateProvider>
                            <AuthenticationProvider>
                                <Suspense fallback={null}>
                                    <SyncronizationProvider>
                                        <Routes />
                                    </SyncronizationProvider>
                                </Suspense>
                            </AuthenticationProvider>
                        </GlobalStateProvider>
                    </ApolloProvider>
                </TueriProvider>
            </IntlProvider>
        </RefreshProvider>
    </NetworkProvider>, 
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
    onUpdate: (e) => console.log('SERVICE WORKER IS UPDATED',e),
    onSuccess: (e) => console.log('SERVICE WORKER IS INSTALLED',e)
});
// serviceWorker.unregister();

