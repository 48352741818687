import gql from 'graphql-tag'

export const GET_RESPONSES = gql`
    query Responses($filter: ResponseFilter) {
        onTrack {
            responses(filter: $filter) {
                uuid
                installerNavisionId
                bdsId
                isComplete
                # signature
                overallPoints
                goldStandardPoints
                createdAt
                updatedAt
                answers {
                    id
                    answerBoolean
                    answerString
                    answerText
                    answerInteger
                    answerFloat
                    answerJson
                    points
                    requiresAction
                    actionPriority
                    actionComplete
                    notes
                    createdAt
                    updatedAt

                    question {
                        id
                        dataType
                        inputType
                        slug
                        pointValue
                        goldStandard
                        qualifier
                        id
                    }
                }
            }
        }
    }
`

export const GET_RESPONSE = gql`
    query Response($filter: ResponseFilter) {
        onTrack {
            response(filter: $filter) {
                uuid
                installerNavisionId
                bdsId
                isComplete
                # signature
                overallPoints
                goldStandardPoints
                createdAt
                updatedAt
                answers {
                    id
                    answerBoolean
                    answerString
                    answerText
                    answerInteger
                    answerFloat
                    answerJson
                    points
                    requiresAction
                    actionPriority
                    actionComplete
                    notes
                    createdAt
                    updatedAt

                    question {
                        id
                        dataType
                        inputType
                        slug
                        pointValue
                        goldStandard
                        qualifier
                        id
                    }
                }
            }
        }
    }
`