import React from 'react'

export default function GoldStandard() {

    return  (
        <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className='napaHex' d="M21.7877 24.0007H7.67174C7.63946 23.9913 7.60668 23.9838 7.57355 23.9781C6.93737 23.9015 6.40954 23.6218 6.02813 23.1076C5.73763 22.7154 5.52296 22.274 5.27981 21.8516C4.61206 20.6922 3.94257 19.5338 3.27134 18.3765C2.32781 16.7441 1.38458 15.112 0.441634 13.48C0.187742 13.0393 0.0137364 12.5678 0.00066416 12.0545C-0.0118271 11.5291 0.152883 11.0436 0.40997 10.5928C1.02466 9.51458 1.64748 8.44104 2.26739 7.36576C2.96458 6.15527 3.66254 4.94488 4.36127 3.73458C4.82054 2.94016 5.27923 2.14545 5.74518 1.35538C6.25849 0.484944 7.00535 0.00330163 8.03573 0.00330163C12.4022 0.00330163 16.7687 0.00330163 21.135 0.00330163C21.4255 0.00330163 21.716 -0.00946479 22.0038 0.0323162C22.6862 0.133287 23.2492 0.442873 23.6094 1.03506C24.1323 1.89737 24.6157 2.78493 25.1328 3.65189C25.8355 4.83017 26.4728 6.04588 27.1857 7.21894C27.7397 8.13087 28.2556 9.06572 28.793 9.98781C29.1343 10.5733 29.4817 11.1551 29.5721 11.8447V12.1059C29.491 12.5489 29.3833 12.9836 29.1576 13.3796C28.8758 13.8743 28.5955 14.3699 28.3096 14.8623C27.8033 15.7327 27.2903 16.6031 26.786 17.4759C26.2454 18.4122 25.714 19.354 25.1746 20.2903C24.7174 21.085 24.2613 21.8803 23.7918 22.6678C23.5013 23.1523 23.1266 23.5545 22.5976 23.7903C22.3397 23.9064 22.0631 23.9508 21.7877 24.0007Z" fill="#ffc72c"/>
            <path d="M15 15.9525L19.635 18.75L18.405 13.4775L22.5 9.93L17.1075 9.4725L15 4.5L12.8925 9.4725L7.5 9.93L11.595 13.4775L10.365 18.75L15 15.9525Z" fill="white"/>
        </svg>
    )

}