import React, { createContext, useContext, useState, useEffect } from 'react'
import db from '../offlineDb'
import { useMutation } from 'react-apollo'
import { SYNC_SURVEY_RESPONSE } from '../operations/Survey'
import { useNetwork } from './network'
import axios from 'axios'

const SyncronizationContext = createContext()

const DATA_TYPES = {
    BOOLEAN: 'answerBoolean',
    STRING: 'answerString',
    TEXT: 'answerText',
    INTEGER: 'answerInteger',
    FLOAT: 'answerFloat',
    JSON: 'answerJson',
    FILE: 'answerJson',
}

const DATA_TYPE_FUNCTIONS = {
    STRING: (e) => e,
    TEXT: (e) => e,
    BOOLEAN: (e) => e,
    INTEGER: (e) => e ? parseInt(e.toString().replace(/[^0-9]/g, ""), 10) : null,
    FLOAT: (e) => e ? Number(parseFloat(e.toString().replace(/[^0-9.]/g, "")).toFixed(2)) : null,
    JSON: (e) => e,
    FILE: (e) => e,
}

export default function SyncronizationProvider({ children }) {

    const [ lostConnection, setLostConnection ] = useState(false)
    const [ syncronized, setSyncronized ] = useState(false)
    const [ isOnline ] = useNetwork()

    const [ syncSurveyResponse ] = useMutation(SYNC_SURVEY_RESPONSE)

    useEffect(() => {
        if (!isOnline) {
            setLostConnection(true)
        }
    }, [isOnline])

    useEffect(() => {

        const debounce = setTimeout(() => {

            if (!syncronized && isOnline) {

                (async () => {

                    const responses = await db.responses.where({ syncStatus: 'DESYNCRONIZED' }).toArray()
                    // TODO: FIGURE OUT WHY THIS IS CACHING

                    responses.map(async response => {

                        const responseAnswers = response.answers

                        // console.log(responseAnswers)

                        const answers = []
                        const promises = []
                        Object.keys(response.answers).map(i => {
                            const {
                                actionPriority,
                                notes,
                                pointsEarned: points,
                                value,
                                dataType,
                                questionId,
                                // lastUpdated,
                                desyncronized,
                                uploadResults = [],
                            } = response.answers[i]

                            // Only sync modified answers
                            // if (response.lastSync && lastUpdated < response.lastSync) {
                            // if (response.lastSync && lastUpdated < response.lastSync && desyncronized) { console.log('BROKEN SYNC!!!!!!!!!!!!!!!!!!!!!!!!!!!', i, lastUpdated, response.lastSync, desyncronized) }
                            if (!desyncronized && (response.surveyStatus === 'INCOMPLETE' || response.surveyStatus === 'COMPLETE')) {
                                return null
                            }
                            // if (desyncronized) {
                            //     console.log('SYNC-------', i)
                            //     console.log(i, response.answers[i], responseAnswers[i], response.answers[i].desyncronized)
                            // }
                            // console.log(response.answers[i].desyncronized)

                            responseAnswers[i].desyncronized = false

                            if (dataType === 'FILE' && value.length > 0) {

                                const toUpload = []
                                value.map((val) => {
                                    if (!uploadResults.find(file => val.uuid === file.uuid)) {
                                        return toUpload.push(val)
                                    }
                                    return null
                                })

                                // If no uploads return
                                if (toUpload.length < 1) return null

                                const data = new FormData()
                                for (let i = 0; i < toUpload.length; i++) {
                                    data.append('uploads', toUpload[i].blob)
                                }

                                promises.push(
                                    axios({
                                        method: 'POST',
                                        url: process.env.NODE_ENV === 'development' ? 'http://localhost:4000/onTrack/upload' : 'https://napaapi.com/onTrack/upload',
                                        data,
                                        headers: {
                                            'Content-Type': 'multipart/form-data'
                                        },
                                        // onUploadProgress: ({ loaded, total }) => {
                                        //     // setProgress(loaded / total * 100)
                                        //     console.log('PROGRESS', loaded, total)
                                        // }
                                    })
                                    .then(res => {
                                        const uploaded = []
                                        res.data.map((res, i) => uploaded.push({...res, uuid: toUpload[i].uuid}))
                                        // response.answers[i].uploadResults = [
                                        responseAnswers[i].uploadResults = [
                                            ...uploadResults,
                                            ...uploaded,
                                        ]

                                        
                                        
                                        return answers.push({
                                            questionId, 
                                            actionPriority: actionPriority / 400,
                                            notes,
                                            points,
                                            // [DATA_TYPES[dataType]]: DATA_TYPE_FUNCTIONS[dataType](response.answers[i].uploadResults),
                                            [DATA_TYPES[dataType]]: DATA_TYPE_FUNCTIONS[dataType](responseAnswers[i].uploadResults),
                                        })
                                    })
                                    .catch(err => new Error(err))
                                )
                            }
                            
                            else if (dataType !== 'FILE') {

                                return answers.push({
                                    questionId, 
                                    actionPriority: actionPriority / 400,
                                    notes,
                                    points,
                                    [DATA_TYPES[dataType]]: DATA_TYPE_FUNCTIONS[dataType](value),
                                })
                            }

                            return null
                            
                        })

                        await Promise.all(promises)

                        const payload = {
                            uuid: response.uuid,
                            installerNavisionId: response.installerNavisionId,
                            isComplete: response.surveyStatus === 'COMPLETE',
                            signature: response.signature,
                            answers,
                            overallPoints: response.overallPoints,
                            goldStandardPoints: response.goldStandardPoints,
                        }

                        try {

                            await syncSurveyResponse({ 
                                variables: {
                                    payload
                                }
                            })

                            const lastSync = new Date().getTime()

                            // await db.responses.update(response.uuid, { syncStatus: 'SYNCRONIZED', lastSync, answers: response.answers })
                            await db.responses.update(response.uuid, { 
                                syncStatus: 'SYNCRONIZED', 
                                // The INITIALIZING status is used to sync all questions from previous survey
                                surveyStatus: response.surveyStatus === 'INITIALIZING' ? 'INCOMPLETE' : response.surveyStatus, 
                                lastSync, 
                                answers: responseAnswers
                            })

                        }

                        catch (err) {
                            console.log('ERROR SYNCING:', err)
                        }

                        return null

                    })

                    setSyncronized(true)
                    setLostConnection(false)

                })()

            }

        }, 5000)

        return () => clearTimeout(debounce)

    }, [syncronized, isOnline, syncSurveyResponse])

    return (
        <SyncronizationContext.Provider
            value={{
                syncronized,
                lostConnection,
                setSyncronized: (boolean) => setSyncronized(boolean)
            }}
        >
            { children }
        </SyncronizationContext.Provider>
    )

}

export function useSyncronization() {
    return useContext(SyncronizationContext)
}
