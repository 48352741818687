import React from 'react'
import './priorityDivider.css'
import { useTranslation } from 'react-i18next'

export default function PriorityDivider({ high, medium, low }) {

    const { t } = useTranslation()

    const priorities = {
        high: t('cardPriorityTitleHigh', 'high priority'),
        medium: t('cardPriorityTitleMedium', 'medium priority'),
        low: t('cardPriorityTitleLow', 'low priority')
    }

    return (
        <div className={`priorityDividerContainer priorityDividerContainer--${ high ? 'high' : '' }${ medium ? 'medium' : '' }${ low ? 'low' : '' }`}>
            <div className='priorityText'>
                { high && priorities.high }
                { medium && priorities.medium }
                { low && priorities.low }
            </div>
        </div>
    )
}