import React, { useState, useEffect } from 'react'
import db from '../../offlineDb'
import ActionPlan from '../../components/ActionPlan'
import { useQuery } from 'react-apollo'
import { GET_RESPONSE } from '../../operations/Response'

export default function ActionPlanPage({ match, history }) {
    
    const { installerNavisionId, uuid } = match.params

    const [ installer, setInstaller ] = useState()
    const [ survey, setSurvey ] = useState()
    const [ response, setResponse ] = useState()
    const [ tryApollo, setTryApollo ] = useState()

    const { error, loading, data } = useQuery(
        GET_RESPONSE,
        {
            variables: {
                filter: {
                    installerNavisionId: { eq: installerNavisionId },
                    uuid: { eq: uuid }
                }
            },
            skip: !tryApollo
        }
    )

    useEffect(() => {
        (async () => {
            if (!installer) {
                const [ installer ] = await db.installers.where({ navisionId: installerNavisionId }).toArray()
                setInstaller(installer)
            }
        })()        
    }, [installer, installerNavisionId])

    useEffect(() => {
        (async () => {
            if (!survey) {
                const [ survey ] = await db.surveys.where({ id: "1" }).toArray()
                setSurvey(survey)
            }
        })()        
    }, [survey])

    useEffect(() => {
        (async () => {
            if (!response) {
                const [ response ] = await db.responses.where({ installerNavisionId, uuid }).toArray()
                if (!response) {
                    setTryApollo(true)
                } else {
                    setResponse(response)
                }
            }
        })()        
    }, [response, installerNavisionId, uuid])

    if (!installer || !survey) return null

    if (!response && !tryApollo) return null

    if (loading) return 'Loading...'

    if (error) return 'Error'
    
    return <div className='actionPlanMargin actionPlanMargin--sm'><ActionPlan installer={ installer } survey={ survey } response={ response || data.onTrack.response } history={ history } /></div>
}