import React from 'react'
import ActionPlan from '../../components/ActionPlan'
import { useQuery } from 'react-apollo'
import { GET_RESPONSE } from '../../operations/Response'
import { GET_SURVEY } from 'operations/Survey'
import Loader from 'components/Loader'
import { GET_INSTALLER } from 'operations/Installer'

export default function ActionPlanPage({ match, history }) {
    
    const { installerNavisionId, uuid } = match.params

    const { error: surveyError, loading: surveyLoading, data: surveyData } = useQuery(GET_SURVEY)

    const { error: installerError, loading: installerLoading, data: installerData } = useQuery(GET_INSTALLER, { variables: { filter: { navisionId: { eq: installerNavisionId } } } })

    const { error: responseError, loading: responseLoading, data: responseData } = useQuery(
        GET_RESPONSE,
        {
            variables: {
                filter: {
                    installerNavisionId: { eq: installerNavisionId },
                    uuid: { eq: uuid }
                }
            }
        }
    )

    if (surveyLoading || installerLoading || responseLoading) return <Loader />

    if (surveyError || installerError || responseError) return 'Error'
    
    return <div className='actionPlanMargin'><ActionPlan installer={ installerData.installer } survey={ surveyData.onTrack.survey } response={ responseData.onTrack.response } history={ history } live /></div>
}