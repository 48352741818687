import gql from 'graphql-tag'

export const GET_INSTALLER = gql`
    query Installer($filter: InstallerFilter) {
  	    installer(filter: $filter) {
            id
            navisionId
            ownerName
            businessName
            address
            city
            province
            postal
            phone
            email
            fax

            installerNumber
            installerType
            claimBanner
            
            store {
                navisionId
                storeNapaNumber
                businessName
            }

            onTrack {
                rank
            }
        }
    }
`

export const GET_INSTALLERS = gql`
    query Installers($filter: InstallerFilter $limit: Int) {
        installers(filter: $filter limit: $limit) {
            id
            navisionId
            installerType
            businessName
            address
            city
            province
            postal
            phone
            email
            onTrack {
                rank
            }
        }
    }
`

export const GET_INSTALLERS_WITH_HISTORICAL = gql`
    query InstallersWithHistorical($filter: InstallerFilter $limit: Int $bdsId: ID) {
        installers(filter: $filter limit: $limit) {
            id
            navisionId
            installerType
            businessName
            address
            city
            province
            postal
            phone
            email
            onTrack {
                rank
                response(bdsId: $bdsId) {
                    uuid
                    installerNavisionId
                    bdsId
                    isComplete
                    # signature
                    overallPoints
                    goldStandardPoints
                    createdAt
                    updatedAt
                    answers {
                        id
                        answerBoolean
                        answerString
                        answerText
                        answerInteger
                        answerFloat
                        answerJson
                        points
                        requiresAction
                        actionPriority
                        actionComplete
                        notes
                        createdAt
                        updatedAt

                        question {
                            id
                            dataType
                            inputType
                            slug
                            pointValue
                            goldStandard
                            qualifier
                            id
                        }
                    }
                }
            }
        }
    }
`

export const COMPLETE_ACTION_PLAN_ITEM = gql`
    mutation CompleteActionPlanItem($payload: OnTrack_CompleteActionPlanItemPayload!) {
        completeActionPlanItem: onTrack_completeActionPlanItem(payload: $payload)
    }
`