import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink, Observable } from 'apollo-link';
// import { createPersistedQueryLink } from "apollo-link-persisted-queries";

const request = async (operation) => {
    const headers = {}
    const token = localStorage.getItem('accessToken') || ''
    if (token) {
        headers.authorization = `Bearer ${token}`
    }
    headers.lang = 'en'
    headers.client = 'ON_TRACK'
    headers.env = process.env.NODE_ENV
    operation.setContext({ headers })
  };
  
  const requestLink = new ApolloLink((operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));
  
      return () => {
        if (handle) handle.unsubscribe();
      };
    })
  );


const client = new ApolloClient({
    name: `NAPA - OnTrack (${ process.env.NODE_ENV })`,
    version: '1.0.0',
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          );
        if (networkError) {
            if (networkError.statusCode === 401) {

                localStorage.removeItem('accessToken');
                const refreshToken = localStorage.getItem('refreshToken')
                if (refreshToken) {
                }
                else {
                    client.resetStore()
                    localStorage.removeItem('accessToken')
                    localStorage.removeItem('refreshToken')
                }

            }
        }
      }),
      // createPersistedQueryLink({ useGETForHashedQueries: true }),
      requestLink,
      new HttpLink({
        uri: process.env.NODE_ENV === 'development' ? '/api' : 'https://napaapi.com/graphql',
        credentials: 'same-origin',
      })
    ]),
    cache: new InMemoryCache()
  });

export default client