import React from "react";
import { useSurvey } from '../useSurvey'
import { useTranslation } from "react-i18next"
// import accents from 'remove-accents'

// NOTE: The type = "text" declaration below serves as a default value for that component property
export default function Boolean({ label, name, value, type = "text", inputMode = 'text', helper = '', required = false, onChange, placeholder, autoComplete = 'on' }) {

    const { t } = useTranslation()
    // Use global form state
    const { state: { inputs }, setAnswer } = useSurvey()

    return (
        <div className='inputType--boolean'>
            <label>{ label }</label>
            <button type='button' className={ inputs[name] && inputs[name].value === false ? 'selected' : '' } onClick={() => { 
                onChange && onChange({target: {value: false}})
                return setAnswer({ [name]: { ...inputs[name], value: false, pointsEarned: 0 } })
            }}>{ t('booleanNo', 'No') }</button>
            <button type='button' className={ inputs[name] && inputs[name].value ? 'selected' : '' } onClick={() => {
                onChange && onChange({target: {value: true}})
                return setAnswer({ [name]: { ...inputs[name], value: true, pointsEarned: inputs[name].pointValue } })
            }}>{ t('booleanYes', 'Yes') }</button>
        </div>
    )
}
