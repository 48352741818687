import React from "react";
import './InputWrapper.css'
import { CardDivider } from '../../Card'

export default function InputWrapper({ children, isFocused, isFilled, inputId, label, helper, note }) {

    return (
        <>
            <label htmlFor={`${ inputId }${ note ? '-note' : '' }`} className={ note ? 'note' : '' }>{ note ? <CardDivider title={ label } /> : label }</label>
            <div className={ `input-container ${ note ? 'inputContainer--note' : '' } ${isFilled ? 'input-filled' : ''} ${isFocused ? 'input-focused' : ''}` } > 
                { children }
                { helper && <div className='inputHelper'>{ helper }</div> }          
            </div>
        </>
    )
}