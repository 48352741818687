import React, { useState, useEffect, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import './upload.css'
import { useSurvey } from '../useSurvey'
import { useTranslation } from "react-i18next"
import { Img } from '@tueri/react'
import uuid from 'uuid/v4'

export default function Upload({ disabled = false, claimKey = '', onUploadComplete, name, label }) {
    
    const { t } = useTranslation()

    const { state: { inputs }, setAnswer } = useSurvey()

    const input = inputs[name]

    const onDrop = useCallback(acceptedFiles => {

        const files = []
        acceptedFiles.map(file => {
            return files.push({
                uuid: uuid(),
                blob: file
            })
        })

        setAnswer({ [name]: { ...input, pointsEarned: input.pointValue, value: [...input.value, ...files] } })

    }, [name, input, setAnswer])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const [files, setFiles] = useState([])

    useEffect(() => {
        if (input.value.length > 0) {
            const files = []
            input.value.map((inputFile) => {

                if (input.uploadResults && input.uploadResults.find(file => inputFile.uuid === file.uuid)) {
                    return null
                }

                const reader = new FileReader()
                reader.onabort = () => console.log('file reading was aborted')
                reader.onerror = () => console.log('file reading has failed')
                reader.onload = () => {
                    // Do whatever you want with the file contents
                    files.push(reader.result)   
                }
                reader.onloadend = () => {
                    setFiles(files)
                }
                reader.readAsDataURL(inputFile.blob)
                return null
            })
        }
        // eslint-disable-next-line
    }, [input.value])
    
    return(
        <>
            {
                !disabled ? (
                    <>
                        <label>{ label }</label>
                        <div {...getRootProps()} className='upload__dropzone'>
                            <input {...getInputProps()} />
                            {
                                isDragActive ? (
                                    <p>&nbsp;<br/>{ t('uploadDropFilesHere', 'Drop files here') }<br/>&nbsp;</p>
                                ) : (
                                    <p>{ t('uploadDropFilesHere', 'Drop files here') }<br/>{ t('uploadDropFilesOr', 'or') }<br/>{ t('uploadClickToBrowseFiles', 'click to browse files') }</p>
                                )
                            }
                        </div>
                    </>
                ) : null
            }
            {
                files.length > 0 && files.map((file, i) => <div key={i} style={{ display: 'inline-block', maxWidth: '128px' }}><img style={{ width: '100%' }} src={ file } alt='test' /></div>)
            }
            {
                input.uploadResults && input.uploadResults.map((file) => <div key={ file.etag } style={{ display: 'inline-block', width: '128px' }}><Img src={ `https://cdn.tueri.io/68719476737/napaontrack/${ file.key.replace('uploads/', '') }` } alt={ file.key } /></div>)
            }          
        </>
    )

}