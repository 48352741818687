import React, { useState } from 'react'
import { useQuery } from 'react-apollo'
import { GET_INSTALLERS } from '../../operations/Installer'
import { useGlobalState } from '../../hooks/useGlobalState'
import Card from '../../components/Card'
import CardItem from '../../components/CardItem'
import NoData from '../../components/NoData'
// import { useAuthentication } from '../../hooks/useAuthentication'
import { Trans, useTranslation } from 'react-i18next'
import Form, { FormContext, Input } from '../../components/Forms'

import Loader from '../../components/Loader'

export default function Installers() {
    const { t } = useTranslation()

    const [searchTerm, setSearchTerm] = useState('')

    const handleDisplayToggle = (installer) => {
        if(installer.onTrack.rank) {
            return (
                (installer.onTrack.rank.toString().indexOf(searchTerm) > -1) || 
                (installer.navisionId.toLowerCase().indexOf(searchTerm) > -1) || (installer.navisionId.indexOf(searchTerm) > -1) || 
                (installer.businessName.toLowerCase().indexOf(searchTerm) > -1) || (installer.businessName.indexOf(searchTerm) > -1) || 
                (installer.address.toLowerCase().indexOf(searchTerm) > -1) || (installer.address.indexOf(searchTerm) > -1) || 
                (installer.city.toLowerCase().indexOf(searchTerm) > -1) || (installer.city.indexOf(searchTerm) > -1) || 
                (installer.province.toLowerCase().indexOf(searchTerm) > -1) || (installer.province.indexOf(searchTerm) > -1)
            ) 
            ? true : false
        }
        return (
            (installer.navisionId.toLowerCase().indexOf(searchTerm) > -1) || (installer.navisionId.indexOf(searchTerm) > -1) || 
            (installer.businessName.toLowerCase().indexOf(searchTerm) > -1) || (installer.businessName.indexOf(searchTerm) > -1) || 
            (installer.address.toLowerCase().indexOf(searchTerm) > -1) || (installer.address.indexOf(searchTerm) > -1) || 
            (installer.city.toLowerCase().indexOf(searchTerm) > -1) || (installer.city.indexOf(searchTerm) > -1) || 
            (installer.province.toLowerCase().indexOf(searchTerm) > -1) || (installer.province.indexOf(searchTerm) > -1)
        ) 
        ? true : false
    }

    // const { user } = useAuthentication()

    const { bds, setInstaller } = useGlobalState()

    const { error, loading, data } = useQuery(GET_INSTALLERS, { variables: { filter: { bdsId: { eq: bds.id.toString() } }, limit: 200 } })

    if (error) return null

    if (loading) return <Loader />

    const { installers } = data

    return (
        <div className='contentContainer'>

            <h1>{ t('storeMyInstallers', 'My Installers') }</h1>

            {
                installers.length > 0 ? (
                    <div className='installer-list'>
                        <Form className='grid'>
                            <FormContext.Consumer>
                                {([{ inputs }]) => {
                                    return (
                                        <Input label={ t('installerSearchLabel', 'Search') } 
                                            placeholder={ t('installerSearchPlaceholder', 'Search by Installer Number, Name, Address or Rank') }
                                            type='text' 
                                            name='generalSearch' 
                                            formatting={['uppercase']}
                                            onChange={(e) => {
                                                setSearchTerm(e.target.value)
                                                installers.map(installer => handleDisplayToggle(installer))
                                            }}
                                            isSearch
                                            clearSearchDisabled={ searchTerm.length === 0 ? true : false }
                                            handleClearSearch={() => { 
                                                inputs.generalSearch = ''
                                                setSearchTerm('')
                                            }}
                                        />
                                    )
                                }}
                            </FormContext.Consumer>
                        </Form>
                        {
                            installers.map(installer => {
                                const { id, navisionId, businessName, address, city, province } = installer
                                
                                return(
                                    <div key={id}>
                                        { handleDisplayToggle(installer) && 
                                            <div className='cardContainer'>
                                                <Card to={ `/installer/${ navisionId}` } onClick={() => setInstaller(installer)}>
                                                    <div 
                                                    className='grid' 
                                                        style={{ 
                                                            gridTemplateColumns: `
                                                                40px
                                                                minmax(100px, 1fr)
                                                                minMax(200px, 3fr)
                                                                minMax(200px, 3fr)
                                                            `
                                                        }}
                                                    >
                                                        {/* <InstallerLogo banner={ installerType } /> */}
                                                        <CardItem heading={ t('installerRank', 'Rank') }>{ installer.onTrack.rank || 'N/A' }</CardItem>
                                                        <CardItem heading={ t('installerNumber', 'Installer Number') }>{ navisionId }</CardItem>
                                                        <CardItem heading={ t('businessName', 'Business Name') }>{ businessName }</CardItem>
                                                        <CardItem heading={ t('address', 'Address') }>{ address }<br/>{ city }, { province } </CardItem>
                                                        
                                                    </div>
                                                </Card>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                        {/* Display only if no matching installers are available for searchTerm */}
                        { installers.every(installer => !handleDisplayToggle(installer)) && <p>{ t('installerSearchNoMatches', 'Sorry, no matches could be found.') }</p> }
                    </div>
                ) : (
                    <NoData><Trans i18nKey='noStoreInstallers'>There are no AutoPro Installers linked to your account.</Trans></NoData>
                )
            }
            
        </div>
    )

}