import { useEffect } from 'react'
import { useSurvey } from '../useSurvey'

export default function Answer({ question, children }) {

    const { state: { inputs }, setInitialState } = useSurvey()

    const name = question.slug

    const INPUT_TYPES = {
        INPUT: '',
        BOOLEAN: null,
        SELECT: '',
        FILE: []
    }

    useEffect(() => {
        if (!inputs[name]) {
            setInitialState({
                [name]: {
                    value: INPUT_TYPES[question.inputType],
                    actionPriority: 0,
                    notes: null,
                    pointValue: question.pointValue,
                    pointsEarned: 0,
                    goldStandard: question.goldStandard,
                    qualifier: question.qualifier,
                    dataType: question.dataType,
                    questionId: question.id,
                    lastUpdated: null,
                    desyncronized: false
                }
            })
        }
        // eslint-disable-next-line
    }, [])

    if (!inputs[name]) return null

    return children

}