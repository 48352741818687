import React, { createContext, useContext, useState, useEffect } from 'react'

const NetworkContext = createContext()

export default function NetworkProvider({ children }) {

    const [ state, setState ] = useState(true)

    useEffect(() => {
        window.addEventListener('online', setNetworkOnline)
        return () => window.removeEventListener('online', setNetworkOnline)
    }, [])

    useEffect(() => {
        window.addEventListener('offline', setNetworkOffline)
        return () => window.removeEventListener('offline', setNetworkOffline)
    }, [])

    function setNetworkOnline() {
        setState(true)
    }

    function setNetworkOffline() {
        setState(false)
    }

    return (
        <NetworkContext.Provider
            value={[ state ]}
        >
            { children }
        </NetworkContext.Provider>
    )

}

export function useNetwork() {
    return useContext(NetworkContext)
}
