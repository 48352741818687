import React from 'react'
import './installerCard.css'

import { Graph } from '../../components/DualPointGraph'
import Card from '../../components/Card'
import { useSurvey } from '../../components/Survey'
import { useTranslation } from 'react-i18next'

export default function InstallerCard({ installer, surveyData = {}, actionPlan, shouldUseSurvey, displayToggle, fixed, onButtonClick, buttonText, dark }) {
    const { t } = useTranslation()

    const survey = useSurvey()

    // const {
    //     overallPoints = 0,
    //     goldStandardPoints = 0,
    //     answers = {}
    // } = surveyData

    const overallPoints = shouldUseSurvey ? survey.overallPoints || 0 : surveyData.overallPoints || 0
    const goldStandardPoints = shouldUseSurvey ? survey.goldStandardPoints || 0 : surveyData.goldStandardPoints || 0
    const answers = shouldUseSurvey ? survey.state.inputs || {} : surveyData.answers || {}

    const highPriorityItems = Object.keys(answers).filter(key => answers[key].actionPriority / 400 === 3 || answers[key].actionPriority === 3)
    const mediumPriorityItems = Object.keys(answers).filter(key => answers[key].actionPriority / 400 === 2 || answers[key].actionPriority === 2)
    const lowPriorityItems = Object.keys(answers).filter(key => answers[key].actionPriority / 400 === 1 || answers[key].actionPriority === 1)

    const El = fixed ? 'div' : Card

    return (
        <El className={`installerCardContainer ${ fixed ? 'installerCardContainer--fixed' : '' } ${ dark ? 'installerCardContainer--dark' : '' }`}>

            <Graph overallPoints={ overallPoints } goldStandardPoints={ goldStandardPoints } hidePoints dark={dark} />

            <div className='installerCardDetails'>

                { !dark && (
                    <div className='installerCardDetail installerCardDetail--blue'>
                        <h2>{ t('installerRank', 'Rank') }</h2>
                        { installer ? (installer.rank ? installer.rank : (installer.onTrack && installer.onTrack.rank ? installer.onTrack.rank : 'N/A') ) : 'N/A' }
                    </div>
                )}                

                <div className='installerCardDetail installerCardDetail--yellow'>
                    <h2>Gold Standard</h2>
                    { goldStandardPoints }/150
                </div>

                <div className='installerCardDetail installerCardDetail--blue'>
                    <h2>OnTrack</h2>
                    { overallPoints }/300
                </div>

                <div className='installerCardDetail--spacer' />

                { dark ? (
                    <>
                        <div className='installerCardDetail installerCardDetail--blue'>
                            <h2>{ t('dcTitle', 'DC') }</h2>
                            { installer.store.dc.dcNum }
                        </div>

                        <div className='installerCardDetail installerCardDetail--blue'>
                            <h2>{ t('storeTitle', 'Store') }</h2>
                            { installer.store.storeNapaNumber.substr(4) }
                        </div>
                    </>
                ) : (
                    <>
                        <div className='installerCardDetail installerCardDetail--red'>
                            <h2>{ t('highPriority', 'High Priority') }</h2>
                            { highPriorityItems.length }
                        </div>

                        <div className='installerCardDetail installerCardDetail--orange'>
                            <h2>{ t('mediumPriority', 'Medium Priority') }</h2>
                            { mediumPriorityItems.length }
                        </div>

                        <div className='installerCardDetail installerCardDetail--yellow'>
                            <h2>{ t('lowPriority', 'Low Priority') }</h2>
                            { lowPriorityItems.length }
                        </div>
                    </>
                ) }

                

            </div>

            <div className='installerCardProfile'>

                <h1>{ installer.businessName }</h1>
                <div>{ installer.address }, { installer.city }, { installer.province } { installer.postal }</div>

            </div>

            {
                (shouldUseSurvey || actionPlan) && (
                    <button type='button' className='installerCardButton installerCardButton--dashboard hideOnPrint' onClick={() => onButtonClick && onButtonClick('DASHBOARD')}>{ t('installerDashboard', 'Installer Dashboard') }</button>
                )   
            }

            {
                shouldUseSurvey && (
                    <>
                        { displayToggle && <button type='button' className='installerCardButton installerCardButton--actionPlan hideOnPrint' onClick={() => onButtonClick && onButtonClick('ACTION_PLAN')}>{ buttonText }</button> }
                    </>
                )
            }

        </El>
    )

}