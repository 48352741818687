import React, { useState } from 'react'
import { withRouter, Link } from 'react-router-dom'
import './nav.css'
import { useTranslation } from 'react-i18next'
import { useAuthentication } from 'hooks/useAuthentication'
import { useApolloClient, useMutation } from 'react-apollo'
import { useGlobalState } from 'hooks/useGlobalState'
import db from '../../offlineDb'

import Card, { CardStatPoints } from '../../components/Card'
import { Graph } from '../../components/DualPointGraph'
import InstallerCard from '../../components/InstallerCard'

import Formatting from '../Formatting'

import Papa from 'papaparse'
import fileDownload from 'js-file-download'
import moment from 'moment'
import useAxios from 'axios-hooks'
import { OAUTH_GENERATE } from '../../operations/Auth'
import { useSyncronization } from 'hooks/useSyncronization'

const statSettings = {

    labourRate: {
        format: 'currency',
        label: 'Labour Rate',
        labelFr: 'Taux horaire'
    },
    numberOfBays: {
        format: 'number',
        label: 'Number of Bays',
        labelFr: "Nombre d'aires de service"
    },
    numberOfHoists: {
        format: 'number',
        label: 'Number of Hoists',
        labelFr: "Nombre d'appareils de levage"
    },
    annualRepairOrders: {
        format: 'number',
        label: 'Annual Number of Repair Orders',
        labelFr: 'Quantité de bons de travail par année'
    },
    repairOrderAmount: {
        format: 'currency',
        label: 'Repair Order Amount',
        labelFr: 'Valeur du bon de travail'
    },
    hoursPerOrder: {
        format: 'number',
        label: 'Hours Per Repair Order',
        labelFr: "Nombre d'heures par bon de travail"
    },
    numberOfServiceAdvisors: {
        format: 'number',
        label: 'Service Advisors',
        labelFr: 'Aviseurs techniques'
    },
    numberOfTechs: {
        format: 'number',
        label: 'Licensed Technicians',
        labelFr: 'Techniciens certifiés'
    },
    hoursPerTech: {
        format: 'number',
        label: 'Billable Hours Per Tech',
        labelFr: 'Heures facturables par technicien'
    },
    numberOfApprentices: {
        format: 'number',
        label: 'Apprentices',
        labelFr: 'Apprentis'
    },
    annualTotalPurchases: {
        format: 'currency',
        label: 'Total Purchases Annually',
        labelFr: 'Achats totaux par année'
    },
    annualTotalNapaPurchases: {
        format: 'currency',
        label: 'NAPA Purchases Annually',
        labelFr: 'Achats NAPA totaux par année'
    },
    annualMarketingBudgetAmount: {
        format: 'currency',
        label: 'Marketing Budget',
        labelFr: 'Budget de marketing'
    },
    annualTrainingBudgetAmount: {
        format: 'currency',
        label: 'Training Budget',
        labelFr: 'Budget de formation'
    },

}

function Nav({ onStatsChange, history }) {
    const { t } = useTranslation()

    const [expanded, setExpanded] = useState(false)
    const [ statsExpanded, setStatsExpanded ] = useState(false)
    const { i18n } = useTranslation()

    const { isAuthenticated, setAuthenticated } = useAuthentication()
    const client = useApolloClient()
    const { resetGlobalState } = useGlobalState()
    const [ oauthGenerate ] = useMutation(OAUTH_GENERATE)
    const { syncronized } = useSyncronization()

    // const syncronized = false

    return (
        <>
            {
                isAuthenticated && (
                    <button type='button' className='nav__stats' onClick={ () => { onStatsChange(!statsExpanded); setStatsExpanded(!statsExpanded); } }>
                        <i className='material-icons'>{ statsExpanded ? 'close' : 'timeline' }</i>
                    </button>
                )
            }

            {/* {
                isAuthenticated ? ( */}
                    <button type='button' className='nav__toggle' onClick={ () => setExpanded(!expanded) }>
                        <i className='material-icons'>{ expanded ? 'close' : 'menu' }</i>
                    </button>
                {/* ) : null
            } */}
            <nav className={`nav__container ${ expanded ? '' : 'nav__container--hidden' }`}>
                <div>
                    <ul>
                        <li><Link to='/' onClick={() => setExpanded(false)}>{ t('navDashboardLink', 'Dashboard') }</Link></li>
                        {/* TODO: Add language-switching ability via react context */}
                        <li><button onClick={() => i18n.changeLanguage(i18n.language.substr(0,2) === 'en' ? 'fr' : 'en')}>{ i18n.language.substr(0,2) === 'fr' ? 'English' : 'Français' }</button></li>
                        <li>
                            <button onClick={async () => { 
                                const { data } = await oauthGenerate()
                                const { oauthToken, oauthRedirect } = data.oauthGenerate
                                const uri = `${ oauthRedirect }/oauth/${ i18n.language }/${ oauthToken }`
                                window.location.replace(uri)
                            }}>
                                { t('navMembershipLink', 'My Programs') }
                            </button>
                        </li>
                        <li><button 
                        disabled={!syncronized}
                        onClick={() => {
                            setAuthenticated(false)
                            setExpanded(false)
                            resetGlobalState()
                            db.delete()
                            db.open()
                            localStorage.removeItem('accessToken')
                            localStorage.removeItem('refreshToken')
                            client.resetStore()
                            history.replace('/')
                        }}>{!syncronized ? <span><s>{ t('logout', 'Logout') }</s> { t('syncing', 'Syncing...') }</span> : t('logout', 'Logout')}</button></li>
                        
                    </ul>

                    <div className='semver'>Version: 1.1.2</div>
                    <Link to='/release-notes' onClick={() => setExpanded(false)}>Release Notes</Link>
                </div>
            </nav>
            
            <div className={`statsContainer ${ statsExpanded ? 'statsContainer--expanded' : '' }`}>
                 <div className='statsContainerMaxWidth'>{ statsExpanded && <Stats /> }</div>
            </div>
        </>
    )
}

export default withRouter(Nav)

function Stats() {
    


    return (
        <>
            <Averages />
        </>
    )
}

function TopAutoPro({ topAutoPro }) {

    // const { error, loading, data } = useQuery(TOP_AUTOPRO)

    // if (loading) return 'Loading...'
    // if (error) return `Error: ${ error.message }`

    // const { topAutoPro } = data.onTrack

    if (!topAutoPro) return null

    return <InstallerCard installer={ topAutoPro.installer } surveyData={{ overallPoints: topAutoPro.overallPoints, goldStandardPoints: topAutoPro.goldStandardPoints }} dark />

}

function Averages() {
    const { t } = useTranslation()
    const { i18n } = useTranslation()

    const [ selectedStat, setSelectedStat ] = useState()
    const [ breakdownSelection, setBreakdownSelection ] = useState()
    const [ errorCount, setErrorCount ] = useState(0)

    // const { error, loading, data } = useQuery(AVERAGES)

    const [{ data: axiosData, loading, error }, refetch] = useAxios({
        url: process.env.NODE_ENV === 'development' ? 'http://localhost:4000/onTrackStats.js' : 'https://napaapi.com/onTrackStats.js',
        method: 'GET',
        headers: {
            'authorization': `Bearer ${ localStorage.getItem('accessToken') }`
        }
    })

    if (loading) return t('statsLoading', 'Loading...')
    if (error && errorCount > 2) return t('statsError', 'Error loading stats.')
    if (error) {
        setErrorCount(errorCount + 1)
        refetch()
        return t('statsLoading', 'Loading...')
    }

    const { data } = axiosData

    const { averages, topAutoPro } = data.onTrack

    const csvData = [
        [
            'National',
            data.installerCount,
            data.onTrack.installersSurveyed,
            averages.national.goldStandard,
            averages.national.overall,
            '$' + averages.labourRate.toFixed(2),
            averages.hoursPerOrder.toFixed(2),
            '$' + averages.repairOrderAmount.toFixed(2),
            averages.hoursPerTech.toFixed(2),
            averages.annualRepairOrders.toFixed(2),
            averages.numberOfHoists.toFixed(2),
            averages.numberOfBays.toFixed(2),
            averages.numberOfTechs.toFixed(2),
            averages.numberOfApprentices.toFixed(2),
            averages.numberOfServiceAdvisors.toFixed(2),
            '$' + averages.annualTotalPurchases.toFixed(2),
            '$' + averages.annualTotalNapaPurchases.toFixed(2),
            '$' + averages.annualMarketingBudgetAmount.toFixed(2),
            '$' + averages.annualTrainingBudgetAmount.toFixed(2),
            averages.actionPlanItemsCompleted
        ]
    ]

    

    const dcChartData = [
        { name: 'OnTrack', data: [] },
        { name: 'Gold Standard', data: [] }
    ]
    data.dcs.map(dc => {

        const { dcNum, onTrack } = dc
        const { overall, goldStandard } = dc.onTrack.averages.dc

        dcChartData[0].data.push([dcNum,overall ])
        dcChartData[1].data.push([dcNum, goldStandard])

        const { averages } = onTrack

        csvData.push([
            '="' + dcNum + '"',
            dc.installerCount || 0,
            dc.onTrack.installersSurveyed || 0,
            goldStandard || 0,
            overall || 0,
            averages.labourRate ? '$' + averages.labourRate.toFixed(2) : 0,
            averages.hoursPerOrder ? averages.hoursPerOrder.toFixed(2) : 0,
            averages.repairOrderAmount ? '$' + averages.repairOrderAmount.toFixed(2) : 0,
            averages.hoursPerTech ? averages.hoursPerTech.toFixed(2) : 0,
            averages.annualRepairOrders ? averages.annualRepairOrders.toFixed(2) : 0,
            averages.numberOfHoists ? averages.numberOfHoists.toFixed(2) : 0,
            averages.numberOfBays ? averages.numberOfBays.toFixed(2) : 0,
            averages.numberOfTechs ? averages.numberOfTechs.toFixed(2) : 0,
            averages.numberOfApprentices ? averages.numberOfApprentices.toFixed(2) : 0,
            averages.numberOfServiceAdvisors ? averages.numberOfServiceAdvisors.toFixed(2) : 0,
            averages.annualTotalPurchases ? '$' + averages.annualTotalPurchases.toFixed(2) : 0,
            averages.annualTotalNapaPurchases ? '$' + averages.annualTotalNapaPurchases.toFixed(2) : 0,
            averages.annualMarketingBudgetAmount ? '$' + averages.annualMarketingBudgetAmount.toFixed(2) : 0,
            averages.annualTrainingBudgetAmount ? '$' + averages.annualTrainingBudgetAmount.toFixed(2) : 0,
            averages.actionPlanItemsCompleted
        ])

        return null
    })


    const bdsChartData = [
        { name: 'OnTrack', data: [] },
        { name: 'Gold Standard', data: [] }
    ]
    const bdsInstallerChartData = [
        { name: 'Installer Base Surveyed', data: [] }
    ]

    data.bdss.map(bds => {

        const { firstName, lastName, onTrack } = bds
        const { overall, goldStandard } = bds.onTrack.averages.bds

        bdsChartData[0].data.push([ `${ firstName } ${ lastName }`, overall ])
        bdsChartData[1].data.push([ `${ firstName } ${ lastName }`, goldStandard ])

        bdsInstallerChartData[0].data.push([`${ firstName } ${ lastName }`, (bds.onTrack.installersSurveyed / bds.installerCount * 100).toFixed()])

        const { averages } = onTrack

        csvData.push([
            firstName + ' ' + lastName,
            bds.installerCount || 0,
            bds.onTrack.installersSurveyed ||0,
            goldStandard || 0,
            overall || 0,
            averages.labourRate ? '$' + averages.labourRate.toFixed(2) : 0,
            averages.hoursPerOrder ? averages.hoursPerOrder.toFixed(2) : 0,
            averages.repairOrderAmount ? '$' + averages.repairOrderAmount.toFixed(2) : 0,
            averages.hoursPerTech ? averages.hoursPerTech.toFixed(2) : 0,
            averages.annualRepairOrders ? averages.annualRepairOrders.toFixed(2) : 0,
            averages.numberOfHoists ? averages.numberOfHoists.toFixed(2) : 0,
            averages.numberOfBays ? averages.numberOfBays.toFixed(2) : 0,
            averages.numberOfTechs ? averages.numberOfTechs.toFixed(2) : 0,
            averages.numberOfApprentices ? averages.numberOfApprentices.toFixed(2) : 0,
            averages.numberOfServiceAdvisors ? averages.numberOfServiceAdvisors.toFixed(2) : 0,
            averages.annualTotalPurchases ? '$' + averages.annualTotalPurchases.toFixed(2) : 0,
            averages.annualTotalNapaPurchases ? '$' + averages.annualTotalNapaPurchases.toFixed(2) : 0,
            averages.annualMarketingBudgetAmount ? '$' + averages.annualMarketingBudgetAmount.toFixed(2) : 0,
            averages.annualTrainingBudgetAmount ? '$' + averages.annualTrainingBudgetAmount.toFixed(2) : 0,
            averages.actionPlanItemsCompleted
        ])

        return null
    })

    const breakdownSelectionData = breakdownSelection ? (
        data[breakdownSelection.type === 'BDS' ? 'bdss' : 'dcs'].filter((res) => res.id === breakdownSelection.id)
    ) : null

    const breakdownData = breakdownSelectionData && breakdownSelectionData[0] ? breakdownSelectionData[0].onTrack : null

    const topTen = breakdownData ? breakdownData.averages.topTen : averages.topTen
    const installerBase = breakdownData ? breakdownData.averages[breakdownSelection.type === 'BDS' ? 'bds' : 'dc'] : averages.national
    
    const breakdownObject = breakdownSelection ? { type: breakdownSelection.type, label: breakdownSelection.type === 'BDS' ? `${ breakdownSelectionData[0].firstName } ${ breakdownSelectionData[0].lastName }` : breakdownSelectionData[0].dcNum } : { type: 'NATIONAL', label: t('breakdownSelectionNational', 'National') }

    const percentageChangeTopTen_goldStandard = (topTen.goldStandard - averages.topTen.goldStandard) / averages.topTen.goldStandard * 100
    const percentageChangeTopTen_overall = (topTen.overall - averages.topTen.overall) / averages.topTen.overall * 100

    const percentageChangeNational_goldStandard = (installerBase.goldStandard - averages.national.goldStandard) / averages.national.goldStandard * 100
    const percentageChangeNational_overall = (installerBase.overall - averages.national.overall) / averages.national.overall * 100

    const csv = Papa.unparse({
        fields: ['', '# of AutoPro\'s', '# of Completed Survey\'s', 'Gold Standard Score', 'OnTrack Score', 'Posted Door Labour Rate', 'Av. Sold Hrs. per WO', 'Avg. Repair Order $', ' Avg. Sold Hrs. per Tech per Day', 'Avg. Repair Orders Annually', 'Number of Hoists', 'Number of Bays', 'Number of Licensed Tech\'s', 'Number of Apprentices', 'Number of Service Advisors', 'Total Annual Purchases', 'Total Annual NAPA Purchases', 'Marketing Budget', 'Training Budget', 'Action Plan Items Completed'],
        data: csvData            
    })

    return (
        <>

            <button type='button' className='nav__stats csvDownloadButton' onClick={() => fileDownload("\uFEFF" + csv, `napaOnTrack_statsExport_${ moment().format('YYYY-MM-DD') }.csv`)}><i className='material-icons'>cloud_download</i></button>

            <h2 style={{ paddingBottom: '3rem', marginTop: '0' }}>
                <div>{ `${ breakdownObject.type !== 'NATIONAL' ? `${ breakdownObject.type }: ` : '' }${ breakdownObject.label }` }</div>
                { t('statsTopInstallerTitle', 'Top Performing Installer') }
            </h2>

            <TopAutoPro topAutoPro={ breakdownData ? breakdownData.topAutoPro : topAutoPro } />

            <h2 className='dark'>
                <div>
                    { i18n.language.substr(0,2) === 'en' ? 
                        `${ breakdownObject.type !== 'NATIONAL' ? `${ breakdownObject.type }: ` : '' }${ breakdownObject.label } Average` 
                        : t('nationalAverageTitle', 'Moyenne nationale') 
                    }
                </div>
                { t('statsSurveyScoresTitle', 'Survey Scores') }
            </h2>

            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gridGap: '2rem'
                }}
            >
                <div>
                    <h3>{ t('statsTopTenInstallersTitle', 'Top 10 Installers') }</h3>
                    <Card dark>
                        <div
                            style={{ padding: '1.5rem 0 3rem 0' }}
                        >
                            <CardStatPoints goldStandard>
                                { topTen.goldStandard }/150
                            </CardStatPoints>

                            {
                                breakdownData && (                                        
                                    <CardStatPoints sm={ true } className='goldStandard'>
                                        <div className={`cardStatPercentage ${ percentageChangeTopTen_goldStandard > 0 ? 'cardStatPercentage--increase' : '' } ${ percentageChangeTopTen_goldStandard < 0 ? 'cardStatPercentage--decrease' : '' }`}>{ (percentageChangeTopTen_goldStandard).toFixed(1) }%{ percentageChangeTopTen_goldStandard > 0 && <i className='material-icons'>arrow_drop_up</i> }{ percentageChangeTopTen_goldStandard < 0 && <i className='material-icons'>arrow_drop_down</i> }</div>
                                        { averages.topTen.goldStandard }/150
                                    </CardStatPoints>
                                )
                            }

                            <CardStatPoints>
                                { topTen.overall }/300
                            </CardStatPoints>

                            {
                                breakdownData && (
                                    <CardStatPoints sm={ true } className='overall'>
                                        <div className={`cardStatPercentage ${ percentageChangeTopTen_overall > 0 ? 'cardStatPercentage--increase' : '' } ${ percentageChangeTopTen_overall < 0 ? 'cardStatPercentage--decrease' : '' }`}>{ (percentageChangeTopTen_overall).toFixed(1) }%{ percentageChangeTopTen_overall > 0 && <i className='material-icons'>arrow_drop_up</i> }{ percentageChangeTopTen_overall < 0 && <i className='material-icons'>arrow_drop_down</i> }</div>
                                        { averages.topTen.overall }/300
                                    </CardStatPoints>
                                )
                            }

                            <div style={{ position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(30px)' }}>
                                <Graph overallPoints={ topTen.overall } goldStandardPoints={ topTen.goldStandard } hidePoints dark />
                            </div>
                        </div>
                    </Card>
                </div>

                <div>
                    <h3>{ t('statsAllInstallersTitle', 'All Installers') }</h3>
                    <Card dark>
                        <div
                            style={{ padding: '1.5rem 0 3rem 0' }}
                        >
                            <CardStatPoints goldStandard>
                                { installerBase.goldStandard }/150
                            </CardStatPoints>

                            {
                                breakdownData && (
                                    <CardStatPoints sm={ true } className='goldStandard'>
                                        <div className={`cardStatPercentage ${ percentageChangeNational_goldStandard > 0 ? 'cardStatPercentage--increase' : '' } ${ percentageChangeNational_goldStandard < 0 ? 'cardStatPercentage--decrease' : '' }`}>{ (percentageChangeNational_goldStandard).toFixed(1) }%{ percentageChangeNational_goldStandard > 0 && <i className='material-icons'>arrow_drop_up</i> }{ percentageChangeNational_goldStandard < 0 && <i className='material-icons'>arrow_drop_down</i> }</div>
                                        { averages.national.goldStandard }/150
                                    </CardStatPoints>
                                )
                            }

                            <CardStatPoints>
                                { installerBase.overall }/300
                            </CardStatPoints>

                            {
                                breakdownData && (
                                    <CardStatPoints sm={ true } className='overall'>
                                        <div className={`cardStatPercentage ${ percentageChangeNational_overall > 0 ? 'cardStatPercentage--increase' : '' } ${ percentageChangeNational_overall < 0 ? 'cardStatPercentage--decrease' : '' }`}>{ (percentageChangeNational_overall).toFixed(1) }%{ percentageChangeNational_overall > 0 && <i className='material-icons'>arrow_drop_up</i> }{ percentageChangeNational_overall < 0 && <i className='material-icons'>arrow_drop_down</i> }</div>
                                        { averages.national.overall }/300
                                    </CardStatPoints>
                                )
                            }

                            <div style={{ position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(30px)' }}>
                                <Graph overallPoints={ installerBase.overall } goldStandardPoints={ installerBase.goldStandard } hidePoints dark />
                            </div>
                        </div>
                    </Card>
                </div>   

            </div>

            <h2 className='dark'>
                <div>
                    { i18n.language.substr(0,2) === 'en' ? 
                        `${ breakdownObject.type !== 'NATIONAL' ? `${ breakdownObject.type }: ` : '' }${ breakdownObject.label } Average` 
                        : t('nationalAverageTitle', 'Moyenne nationale') 
                    }
                </div>
                { t('statsInstallerAnalyticsTitle', 'Installer Analytics') }
            </h2>

            { breakdownSelection && <div className='clearStat--fixed'><button type='button' className='clearStat' onClick={() => setBreakdownSelection()}>{ breakdownSelection.type === 'BDS' ? `BDS: ${ breakdownSelectionData[0].firstName } ${ breakdownSelectionData[0].lastName }` : `DC: ${ breakdownSelectionData[0].dcNum }` }<i className='material-icons'>remove_circle_outline</i></button></div> }

            <div style={{ clear: 'both' }} />

            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gridColumnGap: '2rem'
                }}
            >

                {/* ROW 1 */}

                <Analytic
                    id='labourRate'
                    value={ averages.labourRate }
                    alternateValue={ breakdownData && breakdownData.averages.labourRate }
                    selectedStat={ selectedStat }
                    onClick={ (stat) => setSelectedStat(stat) }
                />
                <Analytic
                    id='numberOfBays'
                    value={ averages.numberOfBays }
                    alternateValue={ breakdownData && breakdownData.averages.numberOfBays }
                    selectedStat={ selectedStat }
                    onClick={ (stat) => setSelectedStat(stat) }
                />
                <Analytic
                    id='numberOfHoists'
                    value={ averages.numberOfHoists }
                    alternateValue={ breakdownData && breakdownData.averages.numberOfHoists }
                    selectedStat={ selectedStat }
                    onClick={ (stat) => setSelectedStat(stat) }
                />

                {/* ROW 2 */}

                <Analytic
                    id='annualRepairOrders'
                    value={ averages.annualRepairOrders }
                    alternateValue={ breakdownData && breakdownData.averages.annualRepairOrders }
                    selectedStat={ selectedStat }
                    onClick={ (stat) => setSelectedStat(stat) }
                />
                <Analytic
                    id='repairOrderAmount'
                    value={ averages.repairOrderAmount }
                    alternateValue={ breakdownData && breakdownData.averages.repairOrderAmount }
                    selectedStat={ selectedStat }
                    onClick={ (stat) => setSelectedStat(stat) }
                />
                <Analytic
                    id='hoursPerOrder'
                    value={ averages.hoursPerOrder }
                    alternateValue={ breakdownData && breakdownData.averages.hoursPerOrder }
                    selectedStat={ selectedStat }
                    onClick={ (stat) => setSelectedStat(stat) }
                />

            </div>

            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                    gridColumnGap: '2rem'
                }}
            >

                {/* ROW 3 */}

                <Analytic
                    id='numberOfServiceAdvisors'
                    value={ averages.numberOfServiceAdvisors }
                    alternateValue={ breakdownData && breakdownData.averages.numberOfServiceAdvisors }
                    selectedStat={ selectedStat }
                    onClick={ (stat) => setSelectedStat(stat) }
                />
                <Analytic
                    id='numberOfTechs'
                    value={ averages.numberOfTechs }
                    alternateValue={ breakdownData && breakdownData.averages.numberOfTechs }
                    selectedStat={ selectedStat }
                    onClick={ (stat) => setSelectedStat(stat) }
                />
                <Analytic
                    id='hoursPerTech'
                    value={ averages.hoursPerTech }
                    alternateValue={ breakdownData && breakdownData.averages.hoursPerTech }
                    selectedStat={ selectedStat }
                    onClick={ (stat) => setSelectedStat(stat) }
                />
                <Analytic
                    id='numberOfApprentices'
                    value={ averages.numberOfApprentices }
                    alternateValue={ breakdownData && breakdownData.averages.numberOfApprentices }
                    selectedStat={ selectedStat }
                    onClick={ (stat) => setSelectedStat(stat) }
                />

                {/* ROW 4 */}

                <Analytic
                    id='annualTotalPurchases'
                    value={ averages.annualTotalPurchases }
                    alternateValue={ breakdownData && breakdownData.averages.annualTotalPurchases }
                    selectedStat={ selectedStat }
                    onClick={ (stat) => setSelectedStat(stat) }
                />
                <Analytic
                    id='annualTotalNapaPurchases'
                    value={ averages.annualTotalNapaPurchases }
                    alternateValue={ breakdownData && breakdownData.averages.annualTotalNapaPurchases }
                    selectedStat={ selectedStat }
                    onClick={ (stat) => setSelectedStat(stat) }
                />
                <Analytic
                    id='annualMarketingBudgetAmount'
                    value={ averages.annualMarketingBudgetAmount }
                    alternateValue={ breakdownData && breakdownData.averages.annualMarketingBudgetAmount }
                    selectedStat={ selectedStat }
                    onClick={ (stat) => setSelectedStat(stat) }
                />
                <Analytic
                    id='annualTrainingBudgetAmount'
                    value={ averages.annualTrainingBudgetAmount }
                    alternateValue={ breakdownData && breakdownData.averages.annualTrainingBudgetAmount }
                    selectedStat={ selectedStat }
                    onClick={ (stat) => setSelectedStat(stat) }
                />

            </div>

            <Breakdown dcs={ data.dcs } bdss={ data.bdss } stat={ selectedStat } onClearStat={() => setSelectedStat()} onSelect={(selection) => setBreakdownSelection(selection)} selected={ breakdownSelection } />

            <div style={{ height: `${ breakdownData ? '40' : '0' }px`}} />
        </>
    )
}


function Breakdown({ dcs, bdss, stat, onClearStat, onSelect, selected }) {
    const { i18n } = useTranslation()
    const { t } = useTranslation()

    const [ statType, setStatType ] = useState('SCORE')
    const [ breakdown, setBreakdown ] = useState('DC')

    let max = 0
    if (stat && bdss) {
        max = bdss.reduce((max, bds) => {
            const value = bds.onTrack.averages[stat]
            return value > max ? value : max
        }, 0)
    }

    let maxActionPlanItemsCompleted = 1
    if (breakdown === 'DC') {
        dcs.map(dc => dc.onTrack.averages.actionPlanItemsCompleted > maxActionPlanItemsCompleted ? maxActionPlanItemsCompleted = dc.onTrack.averages.actionPlanItemsCompleted : null)
    }
    if (breakdown === 'BDS') {
        bdss.map(bds => bds.onTrack.averages.actionPlanItemsCompleted > maxActionPlanItemsCompleted ? maxActionPlanItemsCompleted = bds.onTrack.averages.actionPlanItemsCompleted : null)
    }

    return (
        <>

            <h2 className='dark'>
                <div>
                    {
                        i18n.language.substr(0,2) === 'en' ? `${ breakdown } Average` : `Moyenne des ${ breakdown === 'DC' ? 'CD' : 'SDA' }`
                    }
                </div>
                { t('statsBreakdownTitle', 'Stats Breakdown') }
            </h2>

            <TabSelector style={{ float: 'left' }} onClick={(breakdown) => setBreakdown(breakdown)} tabs={[['DC', t('statsBreakdownDCTab', 'DC Stats')], ['BDS', t('statsBreakdownBDSTab', 'BDS Stats')]]} />

            {
                stat ? (
                    <button type='button' className='clearStat' onClick={() => { onClearStat && onClearStat(); setStatType('SCORE'); }}>{ i18n.language.substr(0,2) === 'en' ? statSettings[stat].label : statSettings[stat].labelFr }<i className='material-icons'>remove_circle_outline</i></button>
                ) : <TabSelector style={{ float: 'right' }} onClick={(statType) => setStatType(statType)} tabs={[['SCORE', t('statsBreakdownScoreTab', 'Score')], ['SURVEYS', t('statsBreakdownSurveysTab', 'Surveys')], ['ACTION_PLANS', t('statsBreakdownActionPlansTab', 'Action Plans')]]} />
            }            

            <div style={{ clear: 'both', marginBottom: '2rem' }} />

            {
                breakdown === 'DC' && (
                    <>
                        {/* <h2 className='dark'>DC Breakdown</h2> */}
                        {
                            dcs && dcs.map(dc => (
                                <Card key={dc.id} dark onClick={() => onSelect && onSelect({ type: 'DC', id: dc.id })}  className={`breakdownCard ${ selected && selected.type === 'DC' && selected.id === dc.id ? 'breakdownCard--selected' : '' }`}>
                                    <div className='bdsCardHeader'>
                                        { dc.dcNum }
                                        <div className='bdsCardStatsContainer'>
                                            {
                                                stat ? (
                                                    <span className='bdsCardStats bdsCardStats--onTrack'>{i18n.language.substr(0,2) === 'en' ? statSettings[stat].label : statSettings[stat].labelFr}: { dc.onTrack.averages[stat] ? <Formatting number={ statSettings[stat].format === 'number' } currency={ statSettings[stat].format === 'currency' } >{ dc.onTrack.averages[stat] }</Formatting> : 'N/A' }</span>
                                                ) : (
                                                    <>
                                                        {
                                                            statType === 'SCORE' && (
                                                                <>
                                                                    <span className='bdsCardStats bdsCardStats--goldStandard'>Gold Standard: { dc.onTrack.averages.dc.goldStandard || 0 }/150</span>
                                                                    <span className='bdsCardStats bdsCardStats--onTrack'>OnTrack: { dc.onTrack.averages.dc.overall || 0 }/300</span>
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            statType === 'SURVEYS' &&(
                                                                <>
                                                                    <span className='bdsCardStats bdsCardStats--installersSurveyed'>{t('statsBreakdownSurveys', 'Surveys')}: { dc.onTrack.installersSurveyed }/{ dc.installerCount } ({ ((dc.onTrack.installersSurveyed || 0) / dc.installerCount * 100).toFixed(1) }%)</span>
                                                                    <span className='bdsCardStats bdsCardStats--revisits'>{t('statsBreakdownRevisits', 'Revisits')}: { dc.onTrack.revisits || 0 }/{ dc.installerCount } ({ ((dc.onTrackRevisits || 0) / dc.installerCount * 100).toFixed(1) }%)</span>
                                                                </>
                                                            )
                                                        }

                                                        {
                                                            statType === 'ACTION_PLANS' &&(
                                                                <>
                                                                    <span className='bdsCardStats bdsCardStats--actionPlansCompleted'>{t('statsBreakdownActionPlansCompleted', 'Action Plan Items Completed')}: { dc.onTrack.averages.actionPlanItemsCompleted }</span>
                                                                </>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <ProgressBar 
                                        type={ stat ? 'ON_TRACK' : (statType === 'SCORE' ? 'GOLD_STANDARD' : (statType === 'SURVEYS' ? 'INSTALLERS_SURVEYED' : 'ACTION_PLANS')) }
                                        percent={ 
                                            stat ? ( dc.onTrack.averages[stat] / max * 100 ) : (
                                                statType === 'SCORE' ? (dc.onTrack.averages.dc.goldStandard / 150 * 100) : (
                                                    statType === 'SURVEYS' ? ((dc.onTrack.installersSurveyed || 0) / (dc.installerCount || 0) * 100 ) : 
                                                        (dc.onTrack.averages.actionPlanItemsCompleted / maxActionPlanItemsCompleted * 100)
                                                )
                                            )
                                        }
                                    />
                                    { !stat && ['SCORE','SURVEYS'].includes(statType) && <ProgressBar type={ statType === 'SCORE' ? 'ON_TRACK' : 'REVISITS' } percent={ statType === 'SCORE' ? (dc.onTrack.averages.dc.overall / 300 * 100) : ( (dc.onTrack.revisits || 0) / (dc.installerCount || 0) * 100 ) } /> }
                                </Card>
                            ))
                        }
                    </>
                )
            }

            {
                breakdown === 'BDS' && (
                    <>
                        {/* <h2 className='dark'>BDS Breakdown</h2> */}
                        {
                            bdss && bdss.map(bds => (
                                <Card key={bds.id} dark onClick={() => onSelect && onSelect({ type: 'BDS', id: bds.id })} className={`breakdownCard ${ selected && selected.type === 'BDS' && selected.id === bds.id ? 'breakdownCard--selected' : '' }`}>
                                    <div className='bdsCardHeader'>
                                        { bds.firstName } { bds.lastName }
                                        <div className='bdsCardStatsContainer'>
                                            {

                                                stat ? (
                                                    <span className='bdsCardStats bdsCardStats--onTrack'>{i18n.language.substr(0,2) === 'en' ? statSettings[stat].label : statSettings[stat].labelFr}: { bds.onTrack.averages[stat] ? <Formatting number={ statSettings[stat].format === 'number' } currency={ statSettings[stat].format === 'currency' } >{ bds.onTrack.averages[stat] }</Formatting> : 'N/A' }</span>
                                                ) : (
                                                    <>
                                                        {
                                                            statType === 'SCORE' && (
                                                                <>
                                                                    <span className='bdsCardStats bdsCardStats--goldStandard'>Gold Standard: { bds.onTrack.averages.bds.goldStandard }/150</span>
                                                                    <span className='bdsCardStats bdsCardStats--onTrack'>OnTrack: { bds.onTrack.averages.bds.overall }/300</span>
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            statType === 'SURVEYS' && (
                                                                <>
                                                                    <span className='bdsCardStats bdsCardStats--installersSurveyed'>{t('statsBreakdownSurveys', 'Surveys')}: { bds.onTrack.installersSurveyed }/{ bds.installerCount } ({ ((bds.onTrack.installersSurveyed || 0) / bds.installerCount * 100).toFixed(1) }%)</span>
                                                                    <span className='bdsCardStats bdsCardStats--revisits'>{t('statsBreakdownRevisits', 'Revisits')}: { bds.onTrack.revisits || 0 }/{ bds.installerCount } ({ ((bds.onTrackRevisits || 0) / bds.installerCount * 100).toFixed(1) }%)</span>
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            statType === 'ACTION_PLANS' &&(
                                                                <>
                                                                    <span className='bdsCardStats bdsCardStats--actionPlansCompleted'>{t('statsBreakdownActionPlansCompleted', 'Action Plan Items Completed')}: { bds.onTrack.averages.actionPlanItemsCompleted }</span>
                                                                </>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <ProgressBar 
                                        type={ stat ? 'ON_TRACK' : (statType === 'SCORE' ? 'GOLD_STANDARD' : (statType === 'SURVEYS' ? 'INSTALLERS_SURVEYED' : 'ACTION_PLANS')) }
                                        percent={ 
                                            stat ? ( bds.onTrack.averages[stat] / max * 100 ) : (
                                                statType === 'SCORE' ? (bds.onTrack.averages.bds.goldStandard / 150 * 100) : (
                                                    statType === 'SURVEYS' ? ((bds.onTrack.installersSurveyed || 0) / (bds.installerCount || 0) * 100 ) : 
                                                        (bds.onTrack.averages.actionPlanItemsCompleted / maxActionPlanItemsCompleted * 100)
                                                )
                                            )
                                        }
                                    />
                                    { !stat && ['SCORE','SURVEYS'].includes(statType) && <ProgressBar type={ statType === 'SCORE' ? 'ON_TRACK' : 'REVISITS' } percent={ statType === 'SCORE' ? (bds.onTrack.averages.bds.overall / 300 * 100) : ( (bds.onTrack.revisits || 0) / (bds.installerCount || 0) * 100 ) } /> }
                                </Card>
                            ))
                        }
                    </>
                )
            }

        </>
    )

}

function ProgressBar({ type, percent }) {

    const types = {
        'GOLD_STANDARD': 'goldStandard',
        'ON_TRACK': 'onTrack',
        'INSTALLERS_SURVEYED': 'installersSurveyed',
        'REVISITS': 'revisits'
    }

    return (
        <div className='progressBarContainer'>
            <div className={`progressBar progressBar--${types[type]}`} style={{ width: `${ percent.toFixed() }%` }}/>
        </div>
    )

}

function TabSelector({ onClick, tabs, style }) {

    const [ activeTab, setActiveTab ] = useState(0)

    return (
        <div className='tabSelectorContainer' style={style}>
            {
                tabs && tabs.map((tab, i) => (
                    <TabSelectorButton key={i} active={ activeTab === i } onClick={() => { setActiveTab(i); onClick(tab[0]) }}>{ tab[1] }</TabSelectorButton>
                ))
            }
        </div>
    )

}

function TabSelectorButton({ children, active, onClick }) {
    return <button type='button' onClick={() => onClick && onClick()} className={`tabSelectorButton tabSelectorButton--${ active ? 'active' : 'inactive' }`}>{ children }</button>
}

function Analytic({ onClick, id, value, alternateValue, selectedStat }) {
    const { i18n } = useTranslation()

    const formatting = {
        number: statSettings[id].format === 'number',
        currency: statSettings[id].format === 'currency'
    }

    const percentageChange = alternateValue && (alternateValue - value) / value * 100

    return (
        <div className={`analytic ${ selectedStat === id ? 'analytic--selected' : '' }`}>
            <h3>{ i18n.language.substr(0,2) === 'en' ? statSettings[id].label : statSettings[id].labelFr }</h3>
            <Card dark onClick={() => onClick && onClick(id)}>
                <div
                    style={{ padding: '1rem 0' }}
                >
                    <CardStatPoints sm={ alternateValue ? true : false }>
                        <Formatting { ...formatting }>{ value }</Formatting>
                    </CardStatPoints>
                    {
                        alternateValue && (
                            <>
                                <div className={`cardStatPercentage ${ percentageChange > 0 ? 'cardStatPercentage--increase' : '' } ${ percentageChange < 0 ? 'cardStatPercentage--decrease' : '' }`}>{ (percentageChange).toFixed(1) }%{ percentageChange > 0 && <i className='material-icons'>arrow_drop_up</i> }{ percentageChange < 0 && <i className='material-icons'>arrow_drop_down</i> }</div>
                                <CardStatPoints>
                                    <Formatting { ...formatting }>{ alternateValue }</Formatting>
                                </CardStatPoints>
                            </>
                        )
                    }
                </div>
            </Card>
        </div>
    )

}