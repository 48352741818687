import React from 'react';
import { Link } from 'react-router-dom'
import './button.css'

export default function Button({ children, context, disabled, onClick, className, to }) {
    const buttonClasses = `btn ${ context ? 'btn-' + context : '' } ${ className || '' }`

    // If button is meant to serve as a link in behaviour
    if(to) {
        return (
            <Link to={ to } className={ buttonClasses } disabled={ disabled }>
                { children }
            </Link>
        )
    }

    return(
        <button type='button'
                onClick={(e) => {
                    if(onClick) { onClick(e) }
                }}
                className={ buttonClasses }
                disabled={ disabled }
        >
            { children }
        </button>
    )
}