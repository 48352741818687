import React, { useState, useEffect } from "react";
import InputWrapper from '../InputWrapper'
import { useSurvey } from '../useSurvey'

// NOTE: The type = "text" declaration below serves as a default value for that component property
export default function Note({ label, name, value = '', type = "text", inputMode = 'text', helper = '', required = false, onChange, placeholder, autoComplete = 'on' }) {
    // const [stateValue, setValue] = useState(value);

    const [isFocused, setFocus] = useState(false)

    // Use global form state
    const { state: { inputs }, setAnswer } = useSurvey()

    const input = inputs[name]

    const [ displayNotes, setDisplayNotes ] = useState(input.notes ? true : false)

    useEffect(() => {
        input.actionPriority > 0 ? setDisplayNotes(true)
        : !input.notes && setDisplayNotes(false)
    }, [ input ])

    const inputId = `f_${name}`

    if (autoComplete === 'on') {
        if (name === 'username') {
            autoComplete = 'username'
        }
        if (type === 'password') {
            autoComplete = 'current-password'
        }
    }

    return (
        <>
            <button className='noteToggle' type='button' onClick={() => displayNotes ? !input.notes && setDisplayNotes(false) : setDisplayNotes(true)}><i className='material-icons'>notes</i></button>
            {
                displayNotes && (
                    <InputWrapper note label={label} inputId={inputId} isFocused={ isFocused } isFilled={ input && input.value ? true : false } helper={ helper } >
                        <textarea 
                            id={`${ inputId }-note`}
                            type={type}
                            inputMode={ inputMode }
                            name={`${ name }-note`}
                            // required={required}
                            value={ input && input.notes ? input.notes : value }
                            onChange={e => {

                                setAnswer({
                                    [name]: { ...input, notes: e.target.value }
                                })

                            }}
                            onFocus={() => setFocus(true)}
                            onBlur={() => setFocus(false)}
                            autoComplete={ autoComplete }
                            placeholder={ placeholder }
                        />
                        {
                            required ? 
                            <span className="required-hint"></span> 
                            :
                            null
                        }
                    </InputWrapper>
                )
            }
            
        </>
    );
}
