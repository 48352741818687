import React from 'react'
import './card-item.css'

export default function CardItem({ heading, children, style }) {
    if(heading) {
        return (
            <div style={style} className='card-item'>
                <h2 className='claim-info-subheading'>{ heading }</h2>
                { children }
            </div>
        )
    }
    return null
}