const operators = {
    IS: function(a, b) { return a === b },
    IS_NOT: function(a, b) { return a !== b },
    IS_GREATER_THAN: function(a, b) { return a > b },
    IS_GREATER_THAN_OR_EQUAL: function(a, b) { return a >= b },
    IS_LESS_THAN: function(a, b) { return a < b },
    IS_LESS_THAN_OR_EQUAL: function(a, b) { return a <= b },
    CONTAINS: function(string, substring) { return string.toUpperCase().indexOf(substring) !== -1 },
    DOES_NOT_CONTAIN: function(string, substring) { return string.toUpperCase().indexOf(substring) === -1 },
    STARTS_WITH: function(string, substring) { return string.toUpperCase().substring(0, substring) === substring },
    ENDS_WITH: function(string, substring) { return string.toUpperCase().slice(-substring.length) === substring },
}

const operations = {
    
    PERCENTAGE: function(first, second) {
        return Number(first) / (second) * 100
    },

}

const qualifierTypes = {

    MAX_POINT_VALUE: function(qualifiers, inputs) {

        const qualifiedPoints = [0]
        qualifiers.filters.map(({ qualifier: { operation, condition }, value }) => {

            if (operation.operator === 'PERCENTAGE') {
                const percentage = operations[operation.operator](inputs[operation.fields[0]].value, inputs[operation.fields[1]].value)
                if (operators[condition.operator](percentage, condition.value) && percentage <= 100) return qualifiedPoints.push(value)
            }

            if (operation.operator === 'VALUE') {
                if (operators[condition.operator](inputs[operation.fields[0]].value, condition.value)) return qualifiedPoints.push(value)
            }

            return null

        })
        
        return Math.max(...qualifiedPoints)

    }

}

export default function calculatePointValue(qualifier, inputs) {
    return qualifierTypes[qualifier.type](qualifier, inputs)
}