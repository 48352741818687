import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

function ScrollRestoration ({ location }) {

    useEffect(() => {
        window.scrollTo(0,0)
    },[location])
    return null
}

export default withRouter(ScrollRestoration)