import React from 'react'
import './changeLog.css'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

export default function ChangeLog() {
    const { t } = useTranslation()

    const { i18n } = useTranslation()
    const isEnglish = i18n.language === 'en'

    return (
        <div className='contentContainer contentContainer--releaseNotes'>
            <h1>{ t('releaseNotes', 'Release Notes') }</h1>

            <Release date='2020-04-15' version='1.1.2'>

                <h3>Bugfixes</h3>
                <Bugfix>Stats page counting deactivated installers.</Bugfix>
            </Release>

            <Release date='2020-01-28' version='1.1.1'>

                <h3>{ t('changeLogBugfixesTitle', 'Bugfixes') }</h3>
                <Bugfix>
                    { isEnglish ? 
                        "Business Development Specialist (BDS) blue-screen error when opening an action plan." 
                        : "Écran bleu lorsque les spécialistes en développement des affaires (SDA) ouvrent un plan d’action."
                    }
                </Bugfix>
            </Release>

            <Release date='2020-01-28' version='1.1.0'>

                <h3>{ t('changeLogFeaturesTitle', 'Features') }</h3>

                <p>
                    { isEnglish ? 
                        "Today we released some major features: Head Office accounts, OnTrack Stats caching and logout."
                        : "Nous avons rendu disponibles des fonctionnalités importantes aujourd’hui : les comptes pour le siège social, la mise en cache des statistiques OnTrack et la déconnexion."
                    }
                </p>

                <h4>{ isEnglish ? "Head Office Accounts" : "Comptes pour le siège social" }</h4>
                <p>
                    { isEnglish ? 
                        "The entry-point for a Head Office Account is the complete list of Business Development Specialists (BDS). You can click on a BDS card to view their Installer Accounts, then click on an Installer card to view their surveys and specific action plans."
                        : "Le point d’entrée pour le compte du siège social est la liste complète des spécialistes en développement des affaires (SDA). Vous pouvez cliquer sur la carte d’un SDA afin de voir les comptes de leurs ateliers, puis cliquer sur la carte de l’atelier pour voir leurs sondages et leurs plans d’action spécifiques."
                    }
                </p>

                <h4>{ isEnglish ? "OnTrack Stats Caching" : "Mise en cache des statistiques OnTrack" }</h4>
                <p>
                    { isEnglish ? 
                        "Stats are now being cached on an hourly basis. This will drastically improve loading times for the stats page."
                        : "Les statistiques sont mises en cache à toutes les heures. Ceci améliorera significativement le temps de chargement de la page des statistiques."
                    }
                </p>

                <h4>{ isEnglish ? "Logout" : "Déconnexion" }</h4>
                <p>
                    { isEnglish ? 
                        "Logout function checks for surveys that are still waiting to sync and will block the logout if necessary."
                        : "La fonction de déconnexion vérifie les sondages qui sont toujours en attente d’être synchronisés et bloquera la déconnexion si nécessaire."
                    }
                </p>

                <h3>{ t('changeLogBugfixesTitle', 'Bugfixes') }</h3>
                <Bugfix>
                    { isEnglish ? 
                        "Live (un-cached) action plans not showing correct priority counts."
                        : "Plans d’action en direct (non mis en cache) ne montrant pas le bon compte des priorités." 
                    }
                </Bugfix>

            </Release>

            <Release date='2020-01-22' version='1.0.4'>

                <h3>{ t('changeLogFeaturesTitle', 'Features') }</h3>

                <p>
                    { isEnglish ? 
                        "Today we released Director accounts. Directors can now view their Business Development Specialists (BDS) and drill-down into their connected Installer lists to view surveys and specific action plans."
                        : "Nous avons lancé aujourd’hui les comptes pour les directeurs. Les directeurs peuvent maintenant voir leurs spécialistes en développement des affaires (SDA) et progresser dans la liste des ateliers qui leur sont reliés afin de voir des sondages et des plans d’actions spécifiques."
                    }
                </p>

                <h3>{ t('changeLogBugfixesTitle', 'Bugfixes') }</h3>
                <Bugfix>
                    { isEnglish ? 
                        "Live (un-cached) action plans not showing correct priority counts." 
                        : "Plans d’action en direct (non mis en cache) ne montrant pas le bon compte des priorités."
                    }
                </Bugfix>

            </Release>

            <Release date='2020-01-17' version='1.0.3'>

                <h3>{ t('changeLogFeaturesTitle', 'Features') }</h3>

                {/* NOTE: Using conditional block here due to nested HTML */}
                { isEnglish ? 
                    <p>
                        OnTrack Stats are now live! Stats can be viewed from any page by clicking the <i className='material-icons'>timeline</i> button in the top left of your screen.
                    </p>
                    : 
                    <p>
                        Les statistiques OnTrack sont maintenant disponibles! Il est possible de voir les statistiques à partir de n’importe quelle page en cliquant sur le bouton <i className='material-icons'>timeline</i> dans le coin supérieur gauche de votre écran.
                    </p> 
                }
                {/* NOTE: Using conditional block here due to nested quotes */}
                { isEnglish ? 
                    <p>
                        The default view displays stats at the "National" level. You can make the stats display more granular by clicking on a Distribution Centre (DC) or Business Development Specialist (BDS) card.
                    </p>
                    : 
                    <p>
                        La vue par défaut montre les statistiques au niveau national. Vous pouvez obtenir une vue plus précise des statistiques en cliquant sur la carte d’un Centre de distribution (CD) ou d’un spécialiste en développement des affaires (SDA).
                    </p>

                }
            </Release>

        </div>
    )
}

function Release({ date, version, children }) {
    const { t } = useTranslation()

    return (
        <div className='releaseNotes__release' >
            <h2>{ moment(date).format('LL') }</h2>
            <h5>{ t('version', 'Version') } { version }</h5>

            { children }

        </div>
    )

}

function Bugfix({ children }) {
    return (
        <div className='releaseNotes__bugfix'><i className='material-icons'>check_circle</i>{ children }</div>
    )
}