import React, { useState, useEffect, useRef } from 'react'
// import { useQuery } from 'react-apollo'
// import { GET_SURVEY } from '../../operations/Survey'
import Card, { CardPointTitle, CardPriorityTitle, CardDivider } from '../../components/Card'
import Button from '../../components/Button'
// import DualPointGraph from '../../components/DualPointGraph'
import InstallerCard from '../../components/InstallerCard'
import uuid from 'uuid/v4'
import Signature from 'react-signature-pad-wrapper'
import db from '../../offlineDb'

import SurveyState, { useSurvey, Answer, Input, Boolean, ActionPlan, Upload, Note } from '../../components/Survey'

import './survey.css'
import PriorityDivider from '../../components/PriorityDivider'
import Formatting from '../../components/Formatting'
// import html2pdf from 'html2pdf-fix-jspdf'
import { useTranslation } from 'react-i18next'
import { useNetwork } from 'hooks/network'
import { useMutation } from '@apollo/react-hooks'
import { COMPLETE_ACTION_PLAN_ITEM } from 'operations/Installer'
import { useParams } from 'react-router'



/**
 * TODO: query survey data on login to pre-load and prevent loading delay
 */

let signaturePad

export default function Survey({ match, step, history }) {
    const { t, i18n } = useTranslation()

    // console.log(match)

    // TODO: move to global state
    const { installerNavisionId, uuid: existingUuid } = match.params

    const [ surveyInit, setSurveyInit ] = useState({ uuid: null })

    useEffect(() => {
        if (!surveyInit.uuid) {
            // TODO: Try to load an open survey from indexDB
            (async () => {
                const responses = await db.responses.where({ installerNavisionId }).toArray()

                if (responses.length > 0) {

                    // Sort responses in decending order
                    responses.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)
                    
                    // Get the latest response
                    // const [ response ] = responses

                    const response = existingUuid ? responses.find((response) => response.uuid === existingUuid) : responses[0]

                    return setSurveyInit({
                        ...response,
                        // If latest survey is complete, generate a new uuid (PrimaryKey)
                        // and if uuid was not set
                        ...(response.surveyStatus === 'COMPLETE' && !existingUuid && {
                            uuid: uuid(),
                            createdAt: new Date().getTime(),
                            lastSync: new Date().getTime(),
                         })
                    })

                }

                // No pre-existing surveys
                setSurveyInit({ uuid: uuid(), createdAt: new Date().getTime(), lastSync: new Date().getTime() })

            })()
        }
        // eslint-disable-next-line
    }, [])

    const [ currentPageIndex, setCurrentPageIndex ] = useState(0)
    const [ currentSectionIndex, setCurrentSectionIndex ] = useState(0)
    const [ displayPages, setDisplayPages ] = useState()

    // Action Plan
    const [ surveyState, setSurveyState ] = useState(step || 'SURVEY')

    const [ survey, setSurvey ] = useState()
    const [ installer, setInstaller ] = useState({})

    // On load, fetch survey questions from IndexedDb
    useEffect(() => {
        (async () => {
            const [ installer ] = await db.installers.where({ navisionId: installerNavisionId }).toArray()
            const [ survey ] = await db.surveys.where({ id: "1" }).toArray()
            setSurvey(survey)
            setInstaller(installer)
        })()
        // eslint-disable-next-line
    }, [])

    if (!survey) return null

    const pages = survey.pages

    // db.surveys.put({ ...surveys[0] })
    // db.responses.add({ response: "GOOD" })

    // const isFirstPage = currentPageIndex === 0
    // const isLastPage = currentPageIndex === (pages.length - 1)

    if (!surveyInit.uuid) return 'Loading...'

    return (
        <SurveyState responseKey={ surveyInit.uuid } createdAt={ surveyInit.createdAt } installerNavisionId={ installerNavisionId } survey={ survey } init={ surveyInit.answers } >
            {/* <Test/> */}
            <InstallerCard installer={ installer } displayToggle={ surveyState !== 'COMPLETE' } shouldUseSurvey fixed onButtonClick={(e) => { e === 'ACTION_PLAN' ? setSurveyState(surveyState === 'ACTION_PLAN' ? 'SURVEY' : 'ACTION_PLAN') : history.push(`/installer/${ installerNavisionId }`) }} buttonText={ surveyState === 'ACTION_PLAN' ? t('goToSurveyButton', 'Survey') : t('goToActionPlanButton', 'Action Plan') } />

            {
                surveyState === 'SURVEY' && (
                    <>

                        
                        
                        <div className='pageNav' >
                            {/* <DualPointGraph onClick={() => setSurveyState('ACTION_PLAN') } /> */}
                            {
                                // !isFirstPage && true === false ? <button type='button' className='navigate' onClick={ () => setCurrentPageIndex(index => index - 1) }><i className='material-icons'>navigate_before</i>Previous</button> : <div></div> // Show back button if not first index
                            }
                            <button type='button' className='pageTitle' onClick={ () => setDisplayPages(true) }>
                                <div className='pageCounter'>Page: { currentPageIndex + 1 }/{ pages.length } - Section: { currentSectionIndex + 1 }/{ pages[currentPageIndex].sections.length }</div>
                                { pages[currentPageIndex][i18n.language === 'fr' ? 'name_fr' : 'name_en'] }: <div className='sectionTitle'>{ pages[currentPageIndex].sections[currentSectionIndex][[i18n.language === 'fr' ? 'name_fr' : 'name_en']] }</div>
                            </button>
                            {
                                // !isLastPage && true === false ? <button type='button' className='navigate' onClick={ () => setCurrentPageIndex(index => index + 1) }>Next<i className='material-icons'>navigate_next</i></button> : <div></div> // show next button if not last page
                            }
                        </div>
                        {
                            displayPages && (
                                <div className='pagesDisplay'>
                                    {
                                    pages.map((page, i) => <Button context={i === currentPageIndex ? 'secondary' : 'primary'} onClick={ () => { window.scrollTo(0,0); setCurrentSectionIndex(0); setCurrentPageIndex(i); setDisplayPages() } } key={ page.slug }>{ page[i18n.language === 'fr' ? 'name_fr' : 'name_en'] }</Button>) 
                                    }
                                </div>
                            )
                        }

                        {
                            <SurveyQuestions questions={ pages[currentPageIndex].sections[currentSectionIndex].questions } />
                        }

                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
                            {
                                currentPageIndex > 0 || currentSectionIndex > 0 ? (
                                    <Button 
                                        onClick={(e) => { 
                                            e.stopPropagation(); 
                                            
                                            if (currentSectionIndex === 0) {
                                                setCurrentPageIndex(index => index - 1)
                                            }
                                            else {
                                                setCurrentSectionIndex(index => index - 1)
                                            }

                                            window.scrollTo(0,0)
                                        }}
                                        context='secondary'>
                                            { t('surveyPrevButton', 'Previous') }
                                    </Button>
                                ) : <div />
                            }
                            <div />
                            {
                                currentPageIndex < pages.length - 1 || currentSectionIndex < pages[currentPageIndex].sections.length - 1 ? (
                                    <Button 
                                        onClick={(e) => { 
                                            e.stopPropagation(); 
                                            
                                            if (currentSectionIndex === (pages[currentPageIndex].sections.length - 1)) {
                                                setCurrentSectionIndex(0)
                                                setCurrentPageIndex(index => index + 1)
                                            }
                                            else {
                                                setCurrentSectionIndex(index => index + 1)
                                            }

                                            window.scrollTo(0,0)
                                        }}
                                        context='primary'>
                                            { t('surveyNextButton', 'Next') }
                                    </Button>
                                ) : <div />
                            }
                        </div>
                    </>
                )
            }

            {
                surveyState === 'ACTION_PLAN' && (
                    <div className='actionPlanMargin actionPlanMargin--sm'><ActionPlanPage onClose={() => setSurveyState('SURVEY')} onApprove={() => { window.scrollTo(0,0); setSurveyState('SIGNATURE') }} pages={ pages } /></div>
                )
            }

            {
                surveyState === 'SIGNATURE' && (
                    <div className='actionPlanMargin actionPlanMargin--lg'><SignaturePage onCancel={() => setSurveyState('ACTION_PLAN') } onComplete={() => setSurveyState('COMPLETE')} /></div>
                )
            }  

            {
                surveyState === 'COMPLETE' && (
                    <div className='actionPlanMargin actionPlanMargin--lg'><ActionPlanPage pages={ pages } complete /></div>
                )
            }
        </SurveyState>
    )

}

const INPUT_TYPES = {
    INPUT: Input,
    BOOLEAN: Boolean,
    // SELECT: Select
    FILE: Upload
}

function SurveyQuestions({ questions }) {

    const { t, i18n } = useTranslation()

    return (
        <div>
            {
                questions.map(question => {

                    const InputType = INPUT_TYPES[question.inputType] || Input
            
                    return (
                        <Card key={ question.slug } goldStandard={ question.goldStandard } style={{ marginBottom: '4rem' }} >
            
                            <Answer question={ question }>
                                <InputType name={ question.slug } inputMode={ question.inputMode } label={ i18n.language === 'fr' ? question.name_fr : question.name_en } helper={ question.helper } />
            
                                {
                                    question.isActionable && (
                                        <ActionPlan name={ question.slug } />
                                    )
                                }
            
                                {
            
                                    question.isActionable && (
                                        <Note label={ t('notesLabel', 'Notes') } name={ question.slug } />
                                    )
            
                                }
            
                            </Answer>
            
                        </Card>
                    )
            
                })
            }
        </div>
    )

}

export function ActionPlanPage({ pages, onClose, onApprove, complete }) {
    const { t, i18n } = useTranslation()

    const pdfEl = useRef(null)

    const setPrint = useState(false)[1]

    const { state: { inputs }, overallPoints, goldStandardPoints } = useSurvey()

    const highPriorityItems = Object.keys(inputs).filter(key => inputs[key].actionPriority / 400 === 3)
    const mediumPriorityItems = Object.keys(inputs).filter(key => inputs[key].actionPriority / 400 === 2)
    const lowPriorityItems = Object.keys(inputs).filter(key => inputs[key].actionPriority / 400 === 1)

    const highPriority = []
    const mediumPriority = []
    const lowPriority = []
    
    pages.map(page => {
        return page.sections.map(section => {
            return section.questions.map(question => {
                
                if (highPriorityItems.includes(question.slug)) {
                    return highPriority.push({
                        ...question,
                        ...inputs[question.slug],
                        section: `${ page[i18n.language === 'fr' ? 'name_fr' : 'name_en'] } - ${ section[i18n.language === 'fr' ? 'name_fr' : 'name_en'] }`,
                    })
                }

                if (mediumPriorityItems.includes(question.slug)) {
                    return mediumPriority.push({
                        ...question,
                        ...inputs[question.slug],
                        section: `${ page[i18n.language === 'fr' ? 'name_fr' : 'name_en'] } - ${ section[i18n.language === 'fr' ? 'name_fr' : 'name_en'] }`,
                    })
                }

                if (lowPriorityItems.includes(question.slug)) {
                    return lowPriority.push({
                        ...question,
                        ...inputs[question.slug],
                        section: `${ page[i18n.language === 'fr' ? 'name_fr' : 'name_en'] } - ${ section[i18n.language === 'fr' ? 'name_fr' : 'name_en'] }`,
                    })
                }

                return null

            })
        })
    })

    function handlePrint() {
        setPrint(true)
        window.print()
    }

    // async function handlePdf() {

    //     const html = pdfEl.current

    //     const opt = {
    //         margin:       .5,
    //         filename:     'actionPlan.pdf',
    //         html2canvas:  { scale: 2 },
    //         jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' },
    //         pagebreak: {
    //             avoid: ['.card']
    //         }
    //     }
        
    //     const doc = html2pdf().set(opt).from(html).save()
        // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        //     const blob = await doc.output('blob')
        //     window.open(URL.createObjectURL(blob), "_blank");
        // } else { 
        //     doc.save()
        // }
    // }

    return (
        <>
            {
                // !complete && <button type='button' className='returnToSurvey' onClick={ () => onClose && onClose() }>Return to Survey</button>
            }

            {
                complete && (
                    <div className='actionPlanActions hideOnPrint'>
                        <button type='button' onClick={() => handlePrint()}><i className='material-icons'>print</i></button>
                        {/* <button type='button' onClick={() => handlePdf()}><i className='material-icons'>picture_as_pdf</i></button>
                        <button type='button'><i className='material-icons'>email</i></button> */}
                    </div>
                )
            }

            {/* <Modal print isActive={ print }> */}
                <div ref={ pdfEl } style={{ margin: '0 10px' }}>

                    <h1 className='actionPlan'>{ t('actionPlanTitle', 'Action Plan') }</h1>
                    
                    <div style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gridGap: '2rem',
                        margin: '0 6rem'
                    }}>

                        <Card goldStandard={ true }>
                            <CardPointTitle title={ t('goldStandardScore', 'Gold Standard Score') }>
                                { goldStandardPoints }/150
                            </CardPointTitle>
                        </Card>

                        <Card>
                            <CardPointTitle title={ t('onTrackScore', 'OnTrack Score') }>
                                { overallPoints }/300
                            </CardPointTitle>
                        </Card>

                    </div>

                    <div style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr',
                        gridGap: '2rem'
                    }}>
                        {/* t('cardPriorityTitleHigh', 'high') */}
                        {/* t('cardPriorityTitleMedium', 'medium') */}
                        {/* t('cardPriorityTitleLow', 'low') */}
                        <Card>
                            <CardPriorityTitle priority='high'>
                                { highPriorityItems.length }
                            </CardPriorityTitle>
                        </Card>

                        <Card>
                            <CardPriorityTitle priority='medium'>
                                { mediumPriorityItems.length }
                            </CardPriorityTitle>
                        </Card>

                        <Card>
                            <CardPriorityTitle priority='low'>
                                { lowPriorityItems.length }
                            </CardPriorityTitle>
                        </Card>

                    </div>

                    {
                        highPriority.length > 0 && (
                            <>
                                <PriorityDivider high />
                                {
                                    highPriority.map((answer, i) => {

                                        const section = !highPriority[i - 1] || highPriority[i - 1].section !== answer.section ? <div className='prioritySectionTitle'>{ answer.section }</div> : ''

                                        return <React.Fragment key={ i }>
                                            { section }
                                            <ActionPlanCard answer={ answer } />
                                        </React.Fragment>

                                    })
                                }
                            </>
                        )
                    }

                    {
                        mediumPriority.length > 0 && (
                            <>
                                <PriorityDivider medium />
                                {
                                    mediumPriority.map((answer, i) => {

                                        const section = !mediumPriority[i - 1] || mediumPriority[i - 1].section !== answer.section ? <div className='prioritySectionTitle'>{ answer.section }</div> : ''

                                        return <React.Fragment key={ i }>
                                            { section }
                                            <ActionPlanCard answer={ answer } />
                                        </React.Fragment>

                                    })
                                }
                            </>
                        )
                    }

                    {
                        lowPriority.length > 0 && (
                            <>
                                <PriorityDivider low />
                                {
                                    lowPriority.map((answer, i) => {

                                        const section = !lowPriority[i - 1] || lowPriority[i - 1].section !== answer.section ? <div className='prioritySectionTitle'>{ answer.section }</div> : ''

                                        return <React.Fragment key={ i }>
                                            { section }
                                            <ActionPlanCard answer={ answer } />
                                        </React.Fragment>

                                    })
                                }
                            </>
                        )
                    }

                    { !complete && (highPriorityItems.length > 0 || mediumPriorityItems.length > 0 || lowPriorityItems.length > 0) && <ActionPlanApprove onClick={() => onApprove && onApprove() } /> }

                </div>
            {/* </Modal> */}
        </>
    )
}

function ActionPlanCard({ answer }) {
    const { t, i18n } = useTranslation()

    const { installerNavisionId } = useParams()

    const [ isOnline ] = useNetwork()
    
    const { state: { inputs }, setAnswer } = useSurvey()

    const [ isEdit, setIsEdit ] = useState(false)

    const question = answer

    const InputType = INPUT_TYPES[question.inputType] || Input

    const [ completeAction ] = useMutation(COMPLETE_ACTION_PLAN_ITEM)

    function completeActionPlanItem() {
        const nextValues = { ...inputs[question.slug] }
        if (question.dataType === 'BOOLEAN') {
            nextValues.value = true
            nextValues.pointsEarned = inputs[question.slug].pointValue
        }
        setAnswer({
            [question.slug]: { ...nextValues, actionPriority: 0 }
        })

        completeAction({
            variables: {
                payload: {
                    installerNavisionId
                }
            }
        })

    }

    if (isEdit) return (
        <Card key={ question.slug } goldStandard={ question.goldStandard } style={{ marginBottom: '4rem' }} >
            <Answer question={ question }>
                <InputType name={ question.slug } inputMode={ question.inputMode } label={ i18n.language === 'fr' ? question.name_fr : question.name_en } helper={ question.helper } />

                {
                    // question.isActionable && (
                    //     <ActionPlan name={ question.slug } onChange={(e) => console.log(e)} />
                    // )
                }

                {

                    question.isActionable && (
                        <Note label={ t('notesLabel', 'Notes') } name={ question.slug } />
                    )

                }

            </Answer>
            <div className='actionPlanItemBottom'><div></div><button type='button' className='edit edit--close' onClick={() => setIsEdit(false)}><i className='material-icons'>done</i>Done</button></div>
        </Card>
    )

    return (
        <Card goldStandard={ answer.goldStandard }>
            <div style={{
                display: 'grid',
                gridTemplateColumns: 'auto auto'
            }}>
                <div>
                    { answer[i18n.language === 'fr' ? 'name_fr' : 'name_en'] }                    
                </div>
                <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
                    {
                        answer.dataType === 'BOOLEAN' ? (
                            // <i className='material-icons'>{  answer.value ? 'check_circle' : 'cancel' }</i>
                            answer.value ? t('booleanAnswerYes', 'Yes') : t('booleanAnswerNo', 'No')
                        ) : (
                            <Formatting format={ answer.formatting }>{ answer.value }</Formatting>
                        )
                    }
                </div>
            </div>
            {
                answer.notes && (
                    <>
                        <CardDivider horizontal title={ t('notesLabel', 'Notes') } />
                        <p>
                            { answer.notes }
                        </p>
                    </>
                )
            }
            <div className='actionPlanItemBottom'>
                {
                    isOnline ? <button type='button' className='actionPlanItemComplete' onClick={() => completeActionPlanItem()}><i className='material-icons'>done</i> Mark Complete</button> : <div/>
                }          
                <button type='button' className='edit edit--open' onClick={() => setIsEdit(true)}><i className='material-icons'>edit</i>Edit</button>
            </div>
        </Card>
    )
}

function ActionPlanApprove({ onClick }) {
    const { t } = useTranslation()

    return (
        <Button context='yellow' onClick={() => onClick && onClick() }>{ t('finalizeActionPlanButton', 'Finalize Action Plan') }</Button>
    )
}

function SignaturePage({ onCancel, onComplete }) {
    const { t } = useTranslation()

    const { finalizeSurvey } = useSurvey()
    const [ signed, setSigned ] = useState()

    return (
        <div style={{ margin: '0 auto', maxWidth: '560px' }}>
            <h1 className='actionPlan'>{ t('actionPlanAgreementTitle', 'Action Plan Agreement') }</h1>
            <p>
                { t('actionPlanAgreement', 'By signing this “Action Plan Agreement” you are confirming that the underlying survey is an acurate representation of the current state of your business.') }
            </p>
            { t('actionPlanInstallerSignature', 'Installer Signature') }
            <div style={{ borderRadius: '5px', border: '1px solid #eee', position: 'relative' }}>
                <Signature 
                    ref={ref => signaturePad = ref} 
                    options={{ onEnd: () => !signaturePad.isEmpty() && setSigned(true) }} 
                />
                <button className='clear' type='button' onClick={() => { signaturePad.clear(); setSigned(); }}>{ t('actionPlanSignatureClearButton', 'Clear') }</button>
            </div>
            <Button context='agree' disabled={ !signed } onClick={ () => { finalizeSurvey(signaturePad ? signaturePad.toDataURL('image/svg+xml') : null); onComplete && onComplete() } }>{ t('actionPlanAgreeButton', 'I Agree') }</Button>
            <Button context='link' onClick={ () => onCancel && onCancel() }>{ t('actionPlanDisagreeButton', 'I Do Not Agree') }</Button>
        </div>
    )

}