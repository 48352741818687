import React, { useState, useEffect } from 'react'
import Card from '../../components/Card'
import db from '../../offlineDb'
import Button from '../../components/Button'
import NoData from '../../components/NoData'

import InstallerCard from '../../components/InstallerCard'
import { Graph } from '../../components/DualPointGraph'

import './installer.css'
import Formatting from '../../components/Formatting'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-apollo'
import { GET_RESPONSES } from '../../operations/Response'
import { useNetwork } from '../../hooks/network'

export default function Installer({ match }) {
    const { t } = useTranslation()

    const [ isOnline ] = useNetwork()

    const { installerNavisionId } = match.params

    const [ loaded, setLoaded ] = useState({ installer: false, surveyData: false })

    const [ installer, setInstaller ] = useState()
    const [ surveyData, setSurveyData ] = useState([])

    useEffect(() => {

        (async () => {
            const [ installer ] = await db.installers.where({ navisionId: installerNavisionId }).toArray()
            setInstaller(installer)
            setLoaded(loaded => ({ ...loaded, installer :true }))
        })()

    }, [installerNavisionId])

    useEffect(() => {

        (async () => {
            
            const responses = await db.responses.where({ installerNavisionId }).toArray()

                if (responses.length > 0) {

                    // Sort responses in decending order
                    responses.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)
                    
                    // Get the latest response
                    // const [ response ] = responses

                    setSurveyData(responses)

                }

                setLoaded(loaded => ({ ...loaded, surveyData:true }))

        })()

    }, [installerNavisionId])

    if (!loaded.installer || !loaded.surveyData) return null

    return (
        <>
            <InstallerCard installer={ installer } surveyData={ surveyData[0] } fixed />

            <div style={{ marginTop: '140px' }}>
            {
                surveyData.length > 0 ? (
                    <>
                        <Button context='yellow' to={`/installer/${ installerNavisionId }/action-plan`}>{ t('viewActionPlanButton', 'View Action Plan') }</Button>
                    </>
                ) : (
                    <NoData extended helper={ t('noDataPrimaryHelper', 'Looks a little empty in here.') } subHelper={ t('noDataSubHelper', `Let's start an action plan.`) }>
                        <Button context='yellow' to={`/installer/${ installerNavisionId }/survey`}>{ t('startActionPlanButton', 'Start an Action Plan') }</Button>
                    </NoData>
                )
            }
            </div>

            {
                surveyData.length > 0 && (
                    <>
                        <h2>{ t('actionPlanHistoryTitle', 'History') }</h2>
                        {
                            surveyData.map((data) => <InstallerActionPlans key={ data.uuid } surveyData={ data } installerNavisionId={ installerNavisionId } />)
                        }
                        
                    </>
                )
            }
            {
                isOnline ? (
                    <HistoricalData installerNavisionId={ installerNavisionId } surveyData={ surveyData } />
                ) : (
                    <div style={{ position: 'relative', height: '200px' }}><NoData>
                        Connect to the internet to see more more action plans.
                    </NoData></div>
                )
            }
            
        </>
    )
}

function InstallerActionPlans({ installerNavisionId, surveyData: { overallPoints, goldStandardPoints, answers, createdAt, surveyStatus, uuid } }) {
    const { t } = useTranslation()
    
    const highPriorityItems = Object.keys(answers).filter(key => answers[key].actionPriority / 400 === 3 || answers[key].actionPriority === 3)
    const mediumPriorityItems = Object.keys(answers).filter(key => answers[key].actionPriority / 400 === 2 || answers[key].actionPriority === 2)
    const lowPriorityItems = Object.keys(answers).filter(key => answers[key].actionPriority / 400 === 1 || answers[key].actionPriority === 1)

    return (
        <>
            <Card className='cardHistory' // TODO: enable opening past action plan (need to open separate from the existing action plan. too much going on with state) to={`/installer/${ installerNavisionId }/action-plan/${ uuid }`}
                to={`/installer/${ installerNavisionId }/action-plan/${ uuid }`}
            >
                <div className='history'>
                    <Graph overallPoints={ overallPoints } goldStandardPoints={ goldStandardPoints } hidePoints />

                    <div className='cardHistoryDetail cardHistoryDetail--yellow'>
                        <h2>Gold Standard</h2>
                        { goldStandardPoints }/150
                    </div>

                    <div className='cardHistoryDetail cardHistoryDetail--blue'>
                        <h2>OnTrack</h2>
                        { overallPoints }/300
                    </div>

                    <div className='cardHistoryDivider'/>

                    <div className='cardHistoryDetail cardHistoryDetail--red'>
                        <h2>{ t('highPriority', 'High Priority') }</h2>
                        { highPriorityItems.length }
                    </div>

                    <div className='cardHistoryDetail cardHistoryDetail--orange'>
                        <h2>{ t('mediumPriority', 'Medium Priority') }</h2>
                        { mediumPriorityItems.length }
                    </div>

                    <div className='cardHistoryDetail cardHistoryDetail--yellow'>
                        <h2>{ t('lowPriority', 'Low Priority') }</h2>
                        { lowPriorityItems.length }
                    </div>

                    <div className='cardHistoryDetail cardHistoryDetail--right'>
                        <h2>{ surveyStatus === 'INCOMPLETE' ? t('actionPlanStartedOnLabel', 'Started On') : t('actionPlanFinalizedOnLabel', 'Finalized On') }</h2>
                        <Formatting format='DATE_SHORT'>{ Number(createdAt) }</Formatting>
                    </div>

                </div>
            </Card>
        </>
    )

}

function HistoricalData({ installerNavisionId, surveyData }) {

    const { loading, error, data } = useQuery(GET_RESPONSES, {
        variables: {
            filter: {
                installerNavisionId: {
                    eq: installerNavisionId
                }
            }
        }
    })

    if (loading) return 'Loading...'
    if (error) return 'Error'

    const { responses } = data.onTrack

    if (responses.length < 1) return null

    const apolloResponses = responses.filter((response) => !surveyData.some((data) => response.uuid === data.uuid))

    if (apolloResponses.length < 1) return null

    return apolloResponses.map(response => {
        return <InstallerActionPlans key={ response.uuid } surveyData={ response } installerNavisionId={ installerNavisionId } />
    })
    
}