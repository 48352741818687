import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Card, { CardPointTitle, CardPriorityTitle, CardDivider, CardPriorityHighlight } from '../../components/Card'
import PriorityDivider from '../../components/PriorityDivider'
import Formatting from '../../components/Formatting'
import InstallerCard from '../../components/InstallerCard'
import TabSelector from 'components/TabSelector'

export default function ActionPlan({ installer, survey, response, history, live }) {

    const { t, i18n } = useTranslation()

    const [ display, setDisplay ] = useState('ACTION_PLAN')
    
    const { pages } = survey
    const { answers: rawAnswers, goldStandardPoints, overallPoints } = response
    
    

    const pdfEl = useRef(null)

    const answers = buildAnswers(rawAnswers)

    function buildAnswers(rawAnswers) {

        if (!live) return rawAnswers

        const DATA_TYPES = {
            BOOLEAN: 'answerBoolean',
            STRING: 'answerString',
            TEXT: 'answerText',
            INTEGER: 'answerInteger',
            FLOAT: 'answerFloat',
            JSON: 'answerJson',
            FILE: 'answerJson',
        }

        const answers = {}
        rawAnswers.map(answer => answers[answer.question.slug] = {
            actionPriority: answer.actionPriority,
            dataType: answer.question.dataType,
            goldStandard: answer.question.goldStandard,
            notes: answer.notes,
            pointValue: answer.question.pointValue,
            pointsEarned: answer.points,
            questionId: answer.question.id,
            value: answer[DATA_TYPES[answer.question.dataType]]
        })

        return answers

    }

    let highPriorityItems = Object.keys(answers).filter(key => answers[key].actionPriority / 400 === 3 || answers[key].actionPriority === 3)
    let mediumPriorityItems = Object.keys(answers).filter(key => answers[key].actionPriority / 400 === 2 || answers[key].actionPriority === 2)
    let lowPriorityItems = Object.keys(answers).filter(key => answers[key].actionPriority / 400 === 1 || answers[key].actionPriority === 1)

    const highPriority = []
    const mediumPriority = []
    const lowPriority = []
    
    pages.map(page => {
        return page.sections.map(section => {
            return section.questions.map(question => {
                
                if (highPriorityItems.includes(question.slug)) {
                    return highPriority.push({
                        ...question,
                        ...answers[question.slug],
                        section: `${ page[i18n.language === 'fr' ? 'name_fr' : 'name_en'] } - ${ section[i18n.language === 'fr' ? 'name_fr' : 'name_en'] }`,
                    })
                }

                if (mediumPriorityItems.includes(question.slug)) {
                    return mediumPriority.push({
                        ...question,
                        ...answers[question.slug],
                        section: `${ page[i18n.language === 'fr' ? 'name_fr' : 'name_en'] } - ${ section[i18n.language === 'fr' ? 'name_fr' : 'name_en'] }`,
                    })
                }

                if (lowPriorityItems.includes(question.slug)) {
                    return lowPriority.push({
                        ...question,
                        ...answers[question.slug],
                        section: `${ page[i18n.language === 'fr' ? 'name_fr' : 'name_en'] } - ${ section[i18n.language === 'fr' ? 'name_fr' : 'name_en'] }`,
                    })
                }

                return null

            })
        })
    })

    function handlePrint() {
        window.print()
    }
    
    return (
        <>
            <InstallerCard installer={ installer } displayToggle={ false } fixed={ !live } surveyData={ response } actionPlan onButtonClick={() => history.push(`/installer/${ installer.navisionId }`)} />
            <div className='actionPlanActions hideOnPrint'>
                <TabSelector tabs={[['ACTION_PLAN', 'Action Plan'], ['SURVEY', 'Survey']]} onClick={(tab) => setDisplay(tab) } />
                <button type='button' onClick={() => handlePrint()}><i className='material-icons'>print</i></button>
            </div>

                <div ref={ pdfEl } style={{ margin: '0 10px' }}>

                    <h1 className='actionPlan'>{ t('actionPlanTitle', 'Action Plan') }</h1>
                    
                    <div style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gridGap: '2rem',
                        margin: '0 6rem'
                    }}>

                        <Card goldStandard={ true }>
                            <CardPointTitle title={ t('goldStandardScore', 'Gold Standard Score') }>
                                { goldStandardPoints }/150
                            </CardPointTitle>
                        </Card>

                        <Card>
                            <CardPointTitle title={ t('onTrackScore', 'OnTrack Score') }>
                                { overallPoints }/300
                            </CardPointTitle>
                        </Card>

                    </div>

                    <div style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr',
                        gridGap: '2rem'
                    }}>
                        <Card>
                            <CardPriorityTitle priority='high'>
                                { highPriorityItems.length }
                            </CardPriorityTitle>
                        </Card>

                        <Card>
                            <CardPriorityTitle priority='medium'>
                                { mediumPriorityItems.length }
                            </CardPriorityTitle>
                        </Card>

                        <Card>
                            <CardPriorityTitle priority='low'>
                                { lowPriorityItems.length }
                            </CardPriorityTitle>
                        </Card>

                    </div>

                    {
                        display === 'ACTION_PLAN' && highPriority.length > 0 && (
                            <>
                                <PriorityDivider high />
                                {
                                    highPriority.map((answer, i) => {

                                        const section = !highPriority[i - 1] || highPriority[i - 1].section !== answer.section ? <div className='prioritySectionTitle'>{ answer.section }</div> : ''

                                        return <React.Fragment key={ i }>
                                            { section }
                                            <ActionPlanCard answer={ answer } />
                                        </React.Fragment>

                                    })
                                }
                            </>
                        )
                    }

                    {
                        display === 'ACTION_PLAN' && mediumPriority.length > 0 && (
                            <>
                                <PriorityDivider medium />
                                {
                                    mediumPriority.map((answer, i) => {

                                        const section = !mediumPriority[i - 1] || mediumPriority[i - 1].section !== answer.section ? <div className='prioritySectionTitle'>{ answer.section }</div> : ''

                                        return <React.Fragment key={ i }>
                                            { section }
                                            <ActionPlanCard answer={ answer } />
                                        </React.Fragment>

                                    })
                                }
                            </>
                        )
                    }

                    {
                        display === 'ACTION_PLAN' && lowPriority.length > 0 && (
                            <>
                                <PriorityDivider low />
                                {
                                    lowPriority.map((answer, i) => {

                                        const section = !lowPriority[i - 1] || lowPriority[i - 1].section !== answer.section ? <div className='prioritySectionTitle'>{ answer.section }</div> : ''

                                        return <React.Fragment key={ i }>
                                            { section }
                                            <ActionPlanCard answer={ answer } />
                                        </React.Fragment>

                                    })
                                }
                            </>
                        )
                    }

                    {
                        display === 'SURVEY' && pages.map(page => {
                            return (
                                <React.Fragment key={ page.id }>
                                    <h1>{ page.name }</h1>
                                    {
                                        page.sections.map(section => {
                                            return (
                                                <React.Fragment key={ section.id }>
                                                    <h2>{ section.name }</h2>
                                                    {
                                                        section.questions.map(question => {
            
                                                            const answer = {
                                                                ...question,
                                                                ...answers[question.slug],
                                                                section: `${ page[i18n.language === 'fr' ? 'name_fr' : 'name_en'] } - ${ section[i18n.language === 'fr' ? 'name_fr' : 'name_en'] }`,
                                                            }
                        
                                                            return <ActionPlanCard key={question.id} answer={ answer } showPriority />
                                                        })
                                                    }
                                                </React.Fragment>
                                            )                                            
                                        })
                                    }
                                </React.Fragment>
                            )
                        })
                    }

                </div>
            {/* </Modal> */}
        </>
    )
}

function ActionPlanCard({ answer, showPriority }) {
    const { t, i18n } = useTranslation()

    let priority = ''

    if (answer.actionPriority / 400 === 3 || answer.actionPriority === 3) priority = 'high'
    if (answer.actionPriority / 400 === 2 || answer.actionPriority === 2) priority = 'medium'
    if (answer.actionPriority / 400 === 1 || answer.actionPriority === 1) priority = 'low'

    console.log(answer)

    return (
        <Card goldStandard={ answer.goldStandard }>
            {
                priority && showPriority && <CardPriorityHighlight priority={ priority } />
            }
            <div style={{
                display: 'grid',
                gridTemplateColumns: 'auto auto'
            }}>
                <div className={`actionPlanCardQuestion ${ priority && showPriority ? `actionPlanCardQuestion--${ priority }` : '' }`} >
                    { answer[i18n.language === 'fr' ? 'name_fr' : 'name_en'] }                    
                </div>
                <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
                    {
                        answer.dataType === 'BOOLEAN' ? (
                            // <i className='material-icons'>{  answer.value ? 'check_circle' : 'cancel' }</i>
                            answer.value ? t('booleanAnswerYes', 'Yes') : t('booleanAnswerNo', 'No')
                        ) : (
                            <Formatting format={ answer.formatting }>{ answer.value }</Formatting>
                        )
                    }
                </div>
            </div>
            {
                answer.notes && (
                    <>
                        <CardDivider horizontal title='Notes' />
                        <p>
                            { answer.notes }
                        </p>
                    </>
                )
            }            
        </Card>
    )
}