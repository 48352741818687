import React from 'react'
import './pointGraph.css'
import { useSurvey } from '../Survey'
import { useTranslation } from 'react-i18next'

export default function DualPointGraph({ onClick }) {
    const { t } = useTranslation()

    const { overallPoints, goldStandardPoints } = useSurvey()

    return (
        <button type='button' className='actionPlanButton' onClick={ () => onClick && onClick() }> { t('viewActionPlanButton', 'View Action Plan') }
            <Graph overallPoints={ overallPoints } goldStandardPoints={ goldStandardPoints } />
        </button>
    )
}

export function Graph({ overallPoints, goldStandardPoints, hidePoints = false, dark = false }) {

    const radius = 27
    const dashArray = 2 * 3.1415927 * radius

    // const points = 160
    const percent = overallPoints / 300 + 1
    const dashOffset = dashArray * percent

    // const goldStandardPoints = 60
    const goldStandardPercent = goldStandardPoints / 150 + 1
    const goldStandardHeight = (27 * goldStandardPercent) - 27

    return (
        <div style={{ width: '60px', height: '60px' }} className={`dualPointGraphContainer ${ dark ? 'dualPointGraphContainer--dark' : '' }`}>
                <svg className='circle' style={{ maxWidth: '60px', transform: 'rotate(90deg) scaleX(-1)', position: 'absolute', top: '0', left: '0' }} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle r={ radius } cx="30" cy="30" fill="transparent" strokeWidth='5' stroke={dark ? '#525252' : '#c6c6c6'}></circle>
                    <circle r={ radius } cx="30" cy="30" fill="transparent" strokeWidth='5' stroke={dark ? '#0080FF' : '#0d2a8a'} strokeDasharray={ dashArray } strokeDashoffset={ dashOffset }></circle>
                </svg>
                <svg width="31" height="27" viewBox="0 0 31 27" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.8734 26.0274H8.13658C8.10288 26.0176 8.06866 26.0097 8.03407 26.0037C7.36991 25.9238 6.81887 25.6318 6.42068 25.095C6.11741 24.6855 5.89329 24.2248 5.63946 23.7837C4.94234 22.5733 4.2434 21.364 3.54265 20.1558C2.55763 18.4517 1.57291 16.7477 0.588498 15.044C0.323441 14.5839 0.141782 14.0916 0.128135 13.5558C0.115094 13.0072 0.287048 12.5005 0.555442 12.0297C1.19716 10.9041 1.84737 9.78339 2.49455 8.66082C3.2224 7.3971 3.95105 6.13347 4.68052 4.86995C5.15999 4.04059 5.63885 3.21093 6.12529 2.38612C6.66117 1.4774 7.44088 0.974574 8.51658 0.974574C13.0751 0.974574 17.6336 0.974574 22.1919 0.974574C22.4952 0.974574 22.7985 0.961246 23.099 1.00486C23.8114 1.11028 24.3991 1.43348 24.7752 2.05171C25.3211 2.95195 25.8257 3.87854 26.3655 4.78362C27.0991 6.01372 27.7645 7.2829 28.5087 8.50755C29.0871 9.45959 29.6257 10.4356 30.1867 11.3982C30.5431 12.0095 30.9058 12.6168 31.0001 13.3368V13.6094C30.9155 14.0719 30.803 14.5257 30.5673 14.9392C30.2732 15.4556 29.9805 15.973 29.6821 16.487C29.1535 17.3957 28.6179 18.3045 28.0914 19.2156C27.527 20.1931 26.9724 21.1763 26.4092 22.1538C25.9318 22.9834 25.4557 23.8137 24.9656 24.6358C24.6624 25.1417 24.2711 25.5615 23.7189 25.8077C23.4496 25.9289 23.1609 25.9753 22.8734 26.0274Z" fill={dark ? '#525252' : '#c9c9c9'} />
                    <clipPath id="napaHexClip">
                        <path d="M22.8734 26.0274H8.13658C8.10288 26.0176 8.06866 26.0097 8.03407 26.0037C7.36991 25.9238 6.81887 25.6318 6.42068 25.095C6.11741 24.6855 5.89329 24.2248 5.63946 23.7837C4.94234 22.5733 4.2434 21.364 3.54265 20.1558C2.55763 18.4517 1.57291 16.7477 0.588498 15.044C0.323441 14.5839 0.141782 14.0916 0.128135 13.5558C0.115094 13.0072 0.287048 12.5005 0.555442 12.0297C1.19716 10.9041 1.84737 9.78339 2.49455 8.66082C3.2224 7.3971 3.95105 6.13347 4.68052 4.86995C5.15999 4.04059 5.63885 3.21093 6.12529 2.38612C6.66117 1.4774 7.44088 0.974574 8.51658 0.974574C13.0751 0.974574 17.6336 0.974574 22.1919 0.974574C22.4952 0.974574 22.7985 0.961246 23.099 1.00486C23.8114 1.11028 24.3991 1.43348 24.7752 2.05171C25.3211 2.95195 25.8257 3.87854 26.3655 4.78362C27.0991 6.01372 27.7645 7.2829 28.5087 8.50755C29.0871 9.45959 29.6257 10.4356 30.1867 11.3982C30.5431 12.0095 30.9058 12.6168 31.0001 13.3368V13.6094C30.9155 14.0719 30.803 14.5257 30.5673 14.9392C30.2732 15.4556 29.9805 15.973 29.6821 16.487C29.1535 17.3957 28.6179 18.3045 28.0914 19.2156C27.527 20.1931 26.9724 21.1763 26.4092 22.1538C25.9318 22.9834 25.4557 23.8137 24.9656 24.6358C24.6624 25.1417 24.2711 25.5615 23.7189 25.8077C23.4496 25.9289 23.1609 25.9753 22.8734 26.0274Z" />
                    </clipPath>
                    {/* <rect width="100%" y={ `${27 - goldStandardHeight}px` } height={`${ goldStandardHeight }px`} style={{ clipPath: 'url(#napaHexClip)' }} fill="#FFBD2F" /> */}
                    <rect width="100%" height={`${ goldStandardHeight }`} style={{ clipPath: 'url(#napaHexClip)', transform: 'rotate(180deg)', transformOrigin: '50% 50%', transition: 'all 250ms' }} fill="#ffc72c" />
                    {/* <rect width="100%" height={`20px`} style={{ clipPath: 'url(#napaHexClip)', paddingTop: '7px' }} fill="#FFBD2F" /> */}
                    
                    <path d="M15.7874 17.6251L20.6262 20.5456L19.3421 15.0413L23.6172 11.3378L17.9875 10.8601L15.7874 5.66895L13.5872 10.8601L7.95752 11.3378L12.2326 15.0413L10.9485 20.5456L15.7874 17.6251Z" fill="white"/>
                </svg>
                {
                    !hidePoints && (
                        <>
                            <div className='overallPoints'>{ overallPoints }&nbsp;/&nbsp;300</div>
                            <div className='goldStandardPoints'>{ goldStandardPoints }&nbsp;/&nbsp;150</div>
                        </>
                    )
                }
                
            </div>
    )
}