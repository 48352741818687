import gql from 'graphql-tag'

export const GET_SURVEY = gql`
    query GetSurvey {
    onTrack {
        survey {
            id
            name
            name_en
            name_fr
            slug
            version
            isActive
            pages {
                id
                name
                name_en
                name_fr
                slug
                sections {
                    id
                    name
                    name_en
                    name_fr
                    slug
                    questions {
                        id
                        name
                        name_en
                        name_fr
                        slug
                        inputType
                        inputMode
                        dataType
                        formatting
                        helper
                        options
                        pointValue
                        isActionable
                        isActive
                        goldStandard
                        qualifier                        
                    }
                }
            }
        
        }
    }
}
`

// export const GET_SURVEY_PAGES = gql`
//     query GetSurveyPages {
//         onTrack {
//             surveys {
//                 name
//                 slug
//                 version
//                 isActive
//                 pages {
//                     id
//                     name
//                     slug                    
//                 }            
//             }
//         }
//     }        
// `

// export const GET_SURVEY_SECTIONS = gql`
//     query GetSurveySections($pageId: ID!) {
//         onTrack {
//             surveySections(pageId: $pageId) {
//                 id
//                 name
//                 slug
//             }
//         }
//     }
// `

// export const GET_SURVEY_QUESTIONS = gql`
//     query GetSurveyQuestions($sectionId: ID!) {
//         onTrack {
//             surveyQuestions(sectionId: $sectionId) {
//                 id
//                 name
//                 slug
//                 inputType
//                 inputMode
//                 dataType
//                 options
//                 pointValue
//                 isActionable
//                 isActive
//                 goldStandard
//                 qualifier
//             }
//         }
//     }
// `

export const SYNC_SURVEY_RESPONSE = gql`
    mutation SyncSurveyResponse($payload: OnTrack_SyncSurveyResponsePayload!) {
        onTrack_syncSurveyResponse(payload: $payload)
    }
`