import React, { useState, useEffect } from 'react'
import db from '../../offlineDb'
import { useSyncronization } from '../../hooks/useSyncronization'
import './waiting.css'

export default function WaitingToSync() {

    const { lostConnection } = useSyncronization()
    const [ surveyCount, setSurveyCount ] = useState(0)

    useEffect(() => {
        // TODO: Need to tie this into live surveys for an accurate count

        const responses = getDesyncronizedResponses()
        setSurveyCount(responses.length)

        const interval = setInterval(() => {
            (async function() {
                const responses = getDesyncronizedResponses()
                setSurveyCount(responses.length)
            })()
        }, 5000);
        
        return () => clearInterval(interval)

    }, [])

    const getDesyncronizedResponses = async () => {
        const responses = await db.responses.where({ syncStatus: 'DESYNCRONIZED' }).toArray()
        return setSurveyCount(responses.length)
    }

    if (lostConnection) return (
        <div className='waitingToSync'>
            <i className='material-icons'>sync</i>
            { surveyCount > 0 && <div className='syncCountBubble'>{ surveyCount }</div> }
        </div>
    )

    return null
}