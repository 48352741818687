import React, { useState, useEffect } from 'react'
import Card from '../../components/Card'
import CardItem from '../../components/CardItem'
import NoData from '../../components/NoData'
import { Trans, useTranslation } from 'react-i18next'
import db from '../../offlineDb'
import Form, { FormContext, Input } from '../../components/Forms'

export default function Installers() {
    const { t } = useTranslation()

    const [searchTerm, setSearchTerm] = useState('')

    const handleDisplayToggle = (installer) => {
        if(installer.rank) {
            return (
                (installer.rank.toString().indexOf(searchTerm) > -1) || 
                (installer.navisionId.toLowerCase().indexOf(searchTerm) > -1) || (installer.navisionId.indexOf(searchTerm) > -1) || 
                (installer.businessName.toLowerCase().indexOf(searchTerm) > -1) || (installer.businessName.indexOf(searchTerm) > -1) || 
                (installer.address.toLowerCase().indexOf(searchTerm) > -1) || (installer.address.indexOf(searchTerm) > -1) || 
                (installer.city.toLowerCase().indexOf(searchTerm) > -1) || (installer.city.indexOf(searchTerm) > -1) || 
                (installer.province.toLowerCase().indexOf(searchTerm) > -1) || (installer.province.indexOf(searchTerm) > -1)
            ) 
            ? true : false
        }
        return (
            (installer.navisionId.toLowerCase().indexOf(searchTerm) > -1) || (installer.navisionId.indexOf(searchTerm) > -1) || 
            (installer.businessName.toLowerCase().indexOf(searchTerm) > -1) || (installer.businessName.indexOf(searchTerm) > -1) || 
            (installer.address.toLowerCase().indexOf(searchTerm) > -1) || (installer.address.indexOf(searchTerm) > -1) || 
            (installer.city.toLowerCase().indexOf(searchTerm) > -1) || (installer.city.indexOf(searchTerm) > -1) || 
            (installer.province.toLowerCase().indexOf(searchTerm) > -1) || (installer.province.indexOf(searchTerm) > -1)
        ) 
        ? true : false
    }

    const [ installers, setInstallers ] = useState([])

    useEffect(() => {
        (async () => {
            const installers = await db.installers.toArray()
            setInstallers(installers)
        })()
    }, [])

    return (
        <div className='contentContainer'>

            <h1>{ t('installersHeading', 'My Installers') }</h1>

            {
                installers.length > 0 ? (
                    <div className='installer-list'>
                        <Form className='grid'>
                            <FormContext.Consumer>
                                {([{ inputs }]) => {
                                    return (
                                        <Input label={ t('installerSearchLabel', 'Search') } 
                                            placeholder={ t('installerSearchPlaceholder', 'Search by Installer Number, Name, Address or Rank') }
                                            type='text' 
                                            name='generalSearch' 
                                            formatting={['uppercase']}
                                            onChange={(e) => {
                                                setSearchTerm(e.target.value)
                                                installers.map(installer => handleDisplayToggle(installer))
                                            }}
                                            isSearch
                                            clearSearchDisabled={ searchTerm.length === 0 ? true : false }
                                            handleClearSearch={() => { 
                                                inputs.generalSearch = ''
                                                setSearchTerm('')
                                            }}
                                        />
                                    )
                                }}
                            </FormContext.Consumer>
                        </Form>
                        {
                            installers.map(installer => {
                                const { id, navisionId, businessName, address, city, province } = installer
                                
                                return(
                                    <div key={id}>
                                        { handleDisplayToggle(installer) &&  
                                            <div className='cardContainer'>
                                                <Card to={ `/installer/${ navisionId}` }>
                                                    <div 
                                                    className='grid' 
                                                        style={{ 
                                                            gridTemplateColumns: `
                                                                40px
                                                                minmax(100px, 1fr)
                                                                minMax(200px, 3fr)
                                                                minMax(200px, 3fr)
                                                            `
                                                        }}
                                                    >
                                                        {/* <InstallerLogo banner={ installerType } /> */}
                                                        <CardItem heading={ t('installerRank', 'Rank') }>{ installer.rank || 'N/A' }</CardItem>
                                                        <CardItem heading={ t('installerNumber', 'Installer Number') }>{ navisionId }</CardItem>
                                                        <CardItem heading={ t('businessName', 'Business Name') }>{ businessName }</CardItem>
                                                        <CardItem heading={ t('address', 'Address') }>{ address }<br/>{ city }, { province } </CardItem>
                                                    </div>
                                                </Card>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                        {/* Display only if no matching installers are available for searchTerm */}
                        { installers.every(installer => !handleDisplayToggle(installer)) && <p>{ t('installerSearchNoMatches', 'Sorry, no matches could be found.') }</p> }
                    </div>
                ) : (
                    <NoData><Trans i18nKey='noStoreInstallers'>There are no AutoPro Installers linked to your account.</Trans></NoData>
                )
            }
            
        </div>
    )

}