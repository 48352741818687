import React from "react";
import { useSurvey } from '../useSurvey'
// import accents from 'remove-accents'
import { useTranslation } from 'react-i18next'

export default function ActionPlan({ label, name, value, type = "text", inputMode = 'text', helper = '', required = false, onChange, placeholder, autoComplete = 'on' }) {
    const { t } = useTranslation()
    
    // Use global form state
    const { state: { inputs }, setAnswer } = useSurvey()

    // On init, set object name

    function roundValue(value) {
        const round = 400 * Math.round(value / 400)
        onChange && onChange({
            prev: inputs[name].actionPriority,
            next: round
        })
        setAnswer({ [name]: { ...inputs[name], actionPriority: round } })
    }

    if (!inputs[name]) return null

    return (
        <div className='inputContainer--slider'>
            <div className='label'>{ t('actionPlanPriorityTitle', 'Action Plan Priority') }</div>
            <div className='sliderBackgroundContainer'>
                <div className={`sliderBackground ${ inputs[name].actionPriority > 0 && inputs[name].actionPriority <= 400 ? 'sliderBackground--low' : '' } ${ inputs[name].actionPriority > 400 && inputs[name].actionPriority <= 800 ? 'sliderBackground--med' : '' } ${ inputs[name].actionPriority > 800 ? 'sliderBackground--hi' : '' }`}>
                    <div className='colorBar' style={{ width: `${ inputs[name].actionPriority / 400 * 100 }%` }} />
                </div>
                <div className={`sliderBackground ${ inputs[name].actionPriority > 400 && inputs[name].actionPriority <= 800 ? 'sliderBackground--med' : '' } ${ inputs[name].actionPriority > 800 ? 'sliderBackground--hi' : '' }`}>
                    <div className='colorBar' style={{ width: `${ (inputs[name].actionPriority - 400) / 400 * 100 }%` }} />
                </div>
                <div className={`sliderBackground ${ inputs[name].actionPriority > 800 ? 'sliderBackground--hi' : '' }`}>
                    <div className='colorBar' style={{ width: `${ (inputs[name].actionPriority - 800) / 400 * 100 }%` }} />
                </div>
            </div>
            <div className='sliderLabelContainer'>
                <div style={{ left: '.7rem' }}>N/A</div>
                <div style={{ left: '33.333%', transform: 'translateX(-50%)' }}>{ t('actionPlanPriorityLow', 'LOW') }</div>
                <div style={{ left: '66.666%', transform: 'translateX(-50%)' }}>{ t('actionPlanPriorityMedium', 'MEDIUM') }</div>
                <div style={{ right: '0px' }}>{ t('actionPlanPriorityHigh', 'HIGH') }</div>
            </div>
            <input className='inputType--slider' name={ name } type='range' min='0' max='1200' value={ inputs[name].actionPriority >= 0 ? inputs[name].actionPriority : '0' } onMouseUp={(e) => roundValue(e.target.value)} onTouchEnd={(e) => roundValue(e.target.value)}
            //  onChange={(e) => setAnswer({ [name]: { ...inputs[name], actionPriority: e.target.value } })} 
                onChange={(e) => roundValue(e.target.value)}
             />
        </div>
    )

    // return (
    //     <InputWrapper label={label} inputId={inputId} isFocused={ isFocused } isFilled={ inputs[name] ? true : false } helper={ helper } >
    //         <input 
    //             id={inputId}
    //             type={type}
    //             inputMode={ inputMode }
    //             name={name}
    //             // required={required}
    //             value={ inputs[name] || value }
    //             onChange={e => {
    //                 setSurveyValue({
    //                     [name]: e.target.value
    //                 });

    //                 // Allow listening to onChange for this specific input
    //                 if (onChange) onChange({ target: { name: e.target.name, value: e.target.value } })
    //             }}
    //             onFocus={() => setFocus(true)}
    //             onBlur={() => setFocus(false)}
    //             autoComplete={ autoComplete }
    //             placeholder={ placeholder }
    //         />
    //         {
    //             required ? 
    //             <span className="required-hint"></span> 
    //             :
    //             null
    //         }
    //     </InputWrapper>
    // );
}
