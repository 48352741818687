import React from 'react'
import { useRefresh } from '../../hooks/useRefresh'
import './refresh.css'
import { useTranslation } from 'react-i18next'

export default function Refresh() {
    const { t } = useTranslation()

    const { shouldRefresh } = useRefresh()

    if (shouldRefresh) return (
        <div className='refreshContainer'>
            { t('refreshBarMsg', 'New features are available. Please close and re-open your browser to load new features.') }
        </div>
    )

    return null
}