import moment from "moment"

export default function Formatting({ format, children, number, currency, decimalPlaces = 2 }) {

    if (format === 'CURRENCY' || currency) return typeof children === 'number' ? new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(children).replace(/US/g, "") : 'N/A'

    if (format === 'DATE_SHORT') return moment(children).format('ll')

    if (number) return new Intl.NumberFormat('en', { maximumFractionDigits: decimalPlaces }).format(children)

    if (!children) return null

    return children

}