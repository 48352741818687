import React from 'react'
import './noData.css'

export default function NoData({ children, extended, helper, subHelper }) {
    return(
        <div className={ extended ? 'noDataExtended' : 'noData' }>
            <div className='noDataExtendedHelper'>{ helper }</div>
            <div className='noDataExtendedSubHelper'>{ subHelper }</div>
            { children }
        </div>
    )
}