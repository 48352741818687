import React, { useState, useEffect } from 'react'
import './offlineSync.css'
import { useQuery } from 'react-apollo'
import { useAuthentication } from '../../hooks/useAuthentication'
import { GET_INSTALLERS_WITH_HISTORICAL } from '../../operations/Installer'
import { GET_SURVEY } from '../../operations/Survey'
import db from '../../offlineDb'
import { useNetwork } from '../../hooks/network'
import { useSyncronization } from '../../hooks/useSyncronization'
import { useTranslation } from 'react-i18next'

export default function OfflineSync({ children }) {

    const { t } = useTranslation()
    
    const [ isOnline ] = useNetwork()
    const { user } = useAuthentication()

    const [ syncComplete, setSyncComplete ] = useState(false)

    const [ installers, setInstallers ] = useState()
    const [ survey, setSurvey ] = useState()

    const [ blockSync, setBlockSync ] = useState(true)
    const { syncronized } = useSyncronization()

    useEffect(() => {

        (async () => {
            const responses = await db.responses.where({ syncStatus: 'DESYNCRONIZED' }).toArray()
            if (responses.length === 0) setBlockSync(false)
        })()        

    }, [])

    useEffect(() => {
        if (syncronized) setBlockSync(false)
    }, [syncronized])

    useEffect(() => {
        if (installers && survey) {
            setTimeout(() => {
                setSyncComplete(true)
            }, 500)            
        }
    }, [ installers, survey ])

    if (!isOnline) return children

    if (!user.linkId) return null

    if (!syncComplete) return (
        <div className='offlineSyncContainer'>

            {
                blockSync ? (
                    <h1>{ t('syncingActionPlans', 'Syncing action plans...') }</h1>
                ) : (
                    <>
                        <h1>{ t('syncingDatabase', 'Syncing database...') }</h1>

                        <div className={`offlineSyncItem offlineSyncItem--${ survey ? 'true' : 'false' }`}><i className='material-icons'>{ survey ? 'check_circle' : 'sync' }</i> { t('questionDataSync', 'Question Data') }</div>
                        <SurveySync onComplete={() => setSurvey(true) } />

                        <div className={`offlineSyncItem offlineSyncItem--${ installers ? 'true' : 'false' }`}><i className='material-icons'>{ installers ? 'check_circle' : 'sync' }</i> { t('installerDataSync', 'Installer Data') }</div>
                        <InstallerSync onComplete={() => setInstallers(true) } />
                    </>
                )
            }

            

        </div>
    )

    return children

}

const DATA_TYPES = {
    BOOLEAN: 'answerBoolean',
    STRING: 'answerString',
    TEXT: 'answerText',
    INTEGER: 'answerInteger',
    FLOAT: 'answerFloat',
    JSON: 'answerJson',
    FILE: 'answerJson',
}

const INPUT_TYPES = {
    INPUT: '',
    BOOLEAN: null,
    SELECT: '',
    FILE: []
}

function InstallerSync({ onComplete, onError }) {

    const { user } = useAuthentication()

    const { error, loading, data } = useQuery(GET_INSTALLERS_WITH_HISTORICAL, { 
        variables: {
            filter: {
                bdsId: { eq: user.linkId.toString() }
            },
            limit: 200,
            bdsId: user.linkId.toString()
        }
     })

    if (loading) return null
    if (error) {
        console.log('ERROR SYNCING INSTALLERS', error.message)
        onError && onError()
        return null
    }

    const installers = []
    const responses = []
    data.installers.map((installer) => {
        const { onTrack, ...installerData } = installer
        installers.push({ ...installerData, rank: onTrack.rank })
        if (onTrack.response) {

            const response = onTrack.response

            const answers = {}
            response.answers.map(answer => {
                return answers[answer.question.slug] = {
                    value: answer.question.dataType === 'BOOLEAN' ? (
                        answer[DATA_TYPES[answer.question.dataType]]
                    ) : (
                        answer.question.dataType === 'FILE' ?
                            []
                        :
                            answer[DATA_TYPES[answer.question.dataType]] || INPUT_TYPES[answer.question.inputType]
                    ),
                    actionPriority: answer.actionPriority * 400,
                    notes: answer.notes,
                    pointValue: answer.question.pointValue,
                    pointsEarned: answer.points,
                    goldStandard: answer.question.goldStandard,
                    qualifier: answer.question.qualifier,
                    dataType: answer.question.dataType,
                    questionId: answer.question.id,
                    lastUpdated: null,
                    desyncronized: false,
                    ...(answer.question.dataType === 'FILE' && ({ uploadResults: answer[DATA_TYPES[answer.question.dataType]] }))
                }
            })
            responses.push({
                uuid: response.uuid,
                installerNavisionId: response.installerNavisionId,
                createdAt: Number(response.createdAt),
                goldStandardPoints: response.goldStandardPoints || 0,
                overallPoints: response.overallPoints || 0,
                signature: "",
                surveyStatus: response.isComplete ? "COMPLETE" : "INCOMPLETE",
                syncStatus: "SYNCRONIZED",
                answers
            })
        }
        return null
    })

    db.installers.bulkPut(installers)
    // TODO: need to check for any existing responses that are "DESYNCRONIZED", remove them from the bulk put
    // ^^^^^^^ this should be complete, but need to test (blocked the download when surveys are waiting to upload)
    db.responses.bulkPut(responses)
    onComplete && onComplete()

    return null

}

function SurveySync({ onComplete, onError }) {

    const { loading, error, data } = useQuery(GET_SURVEY)

    if (loading) return null
    if (error) {
        console.log('ERROR SYNCING SURVEY', error.message)
        onError && onError()
        return null
    }

    const { survey } = data.onTrack

    db.surveys.put(survey)
    onComplete && onComplete()

    return null

}